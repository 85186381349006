import { useMutation } from '@tanstack/react-query';

import type { AuthCustomer } from '@getjust/api-schema';

import { useCustomRouter } from '$hooks/client';
import { useUpdateEmail } from '$hooks/mutations';
import { useLoginAtom } from '$hooks/state';
import { MUTATION_LOGIN_KEY } from '$src/constants';
import { useShowChallengeAtom } from '$src/hooks/state/useShowChallengeAtom';
import { authAPI } from '$src/http';

export const useLogin = () => {
  const { mutateAsync: updateEmail } = useUpdateEmail();
  const { onLoginChange } = useLoginAtom();
  const router = useCustomRouter();
  const { showLoginChallenge } = useShowChallengeAtom();

  return useMutation({
    mutationFn: (email: string) =>
      authAPI.post<AuthCustomer.AuthLoginResponseBody>('/login', {
        email,
        clientId: 'web',
      }),
    mutationKey: [MUTATION_LOGIN_KEY],
    onError: async (error: any, payload: string) => {
      if (!router.pathname.includes('/unauthenticated')) {
        router.push('/unauthenticated');
      }

      if (router.pathname.includes('/unauthenticated')) {
        await updateEmail({
          email: payload,
        });
      }
    },
    onSuccess: ({ data }) => {
      onLoginChange(data);
      showLoginChallenge();
    },
  });
};
