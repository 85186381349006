const countries = [
  {
    code: 'DOM',
    cca2: 'DO',
    name: 'Dominican Republic',
    flag: '🇩🇴',
  },
  {
    code: 'HMD',
    cca2: 'HM',
    name: 'Heard Island and McDonald Islands',
    flag: '🇭🇲',
  },
  {
    code: 'GHA',
    cca2: 'GH',
    name: 'Ghana',
    flag: '🇬🇭',
  },
  {
    code: 'ITA',
    cca2: 'IT',
    name: 'Italy',
    flag: '🇮🇹',
  },
  {
    code: 'KHM',
    cca2: 'KH',
    name: 'Cambodia',
    flag: '🇰🇭',
  },
  {
    code: 'BHR',
    cca2: 'BH',
    name: 'Bahrain',
    flag: '🇧🇭',
  },
  {
    code: 'ISR',
    cca2: 'IL',
    name: 'Israel',
    flag: '🇮🇱',
  },
  {
    code: 'QAT',
    cca2: 'QA',
    name: 'Qatar',
    flag: '🇶🇦',
  },
  {
    code: 'TCA',
    cca2: 'TC',
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
  },
  {
    code: 'ROU',
    cca2: 'RO',
    name: 'Romania',
    flag: '🇷🇴',
  },
  {
    code: 'MDG',
    cca2: 'MG',
    name: 'Madagascar',
    flag: '🇲🇬',
  },
  {
    code: 'GLP',
    cca2: 'GP',
    name: 'Guadeloupe',
    flag: '🇬🇵',
  },
  {
    code: 'LVA',
    cca2: 'LV',
    name: 'Latvia',
    flag: '🇱🇻',
  },
  {
    code: 'NPL',
    cca2: 'NP',
    name: 'Nepal',
    flag: '🇳🇵',
  },
  {
    code: 'UNK',
    cca2: 'XK',
    name: 'Kosovo',
    flag: '🇽🇰',
  },
  {
    code: 'JEY',
    cca2: 'JE',
    name: 'Jersey',
    flag: '🇯🇪',
  },
  {
    code: 'UKR',
    cca2: 'UA',
    name: 'Ukraine',
    flag: '🇺🇦',
  },
  {
    code: 'GRL',
    cca2: 'GL',
    name: 'Greenland',
    flag: '🇬🇱',
  },
  {
    code: 'MRT',
    cca2: 'MR',
    name: 'Mauritania',
    flag: '🇲🇷',
  },
  {
    code: 'DNK',
    cca2: 'DK',
    name: 'Denmark',
    flag: '🇩🇰',
  },
  {
    code: 'LBY',
    cca2: 'LY',
    name: 'Libya',
    flag: '🇱🇾',
  },
  {
    code: 'TKM',
    cca2: 'TM',
    name: 'Turkmenistan',
    flag: '🇹🇲',
  },
  {
    code: 'AGO',
    cca2: 'AO',
    name: 'Angola',
    flag: '🇦🇴',
  },
  {
    code: 'MTQ',
    cca2: 'MQ',
    name: 'Martinique',
    flag: '🇲🇶',
  },
  {
    code: 'GUM',
    cca2: 'GU',
    name: 'Guam',
    flag: '🇬🇺',
  },
  {
    code: 'SGS',
    cca2: 'GS',
    name: 'South Georgia',
    flag: '🇬🇸',
  },
  {
    code: 'ARM',
    cca2: 'AM',
    name: 'Armenia',
    flag: '🇦🇲',
  },
  {
    code: 'THA',
    cca2: 'TH',
    name: 'Thailand',
    flag: '🇹🇭',
  },
  {
    code: 'AZE',
    cca2: 'AZ',
    name: 'Azerbaijan',
    flag: '🇦🇿',
  },
  {
    code: 'GIN',
    cca2: 'GN',
    name: 'Guinea',
    flag: '🇬🇳',
  },
  {
    code: 'CYM',
    cca2: 'KY',
    name: 'Cayman Islands',
    flag: '🇰🇾',
  },
  {
    code: 'SVK',
    cca2: 'SK',
    name: 'Slovakia',
    flag: '🇸🇰',
  },
  {
    code: 'LCA',
    cca2: 'LC',
    name: 'Saint Lucia',
    flag: '🇱🇨',
  },
  {
    code: 'REU',
    cca2: 'RE',
    name: 'Réunion',
    flag: '🇷🇪',
  },
  {
    code: 'SLE',
    cca2: 'SL',
    name: 'Sierra Leone',
    flag: '🇸🇱',
  },
  {
    code: 'CHN',
    cca2: 'CN',
    name: 'China',
    flag: '🇨🇳',
  },
  {
    code: 'GEO',
    cca2: 'GE',
    name: 'Georgia',
    flag: '🇬🇪',
  },
  {
    code: 'JPN',
    cca2: 'JP',
    name: 'Japan',
    flag: '🇯🇵',
  },
  {
    code: 'FLK',
    cca2: 'FK',
    name: 'Falkland Islands',
    flag: '🇫🇰',
  },
  {
    code: 'ERI',
    cca2: 'ER',
    name: 'Eritrea',
    flag: '🇪🇷',
  },
  {
    code: 'FIN',
    cca2: 'FI',
    name: 'Finland',
    flag: '🇫🇮',
  },
  {
    code: 'KIR',
    cca2: 'KI',
    name: 'Kiribati',
    flag: '🇰🇮',
  },
  {
    code: 'ARG',
    cca2: 'AR',
    name: 'Argentina',
    flag: '🇦🇷',
  },
  {
    code: 'PHL',
    cca2: 'PH',
    name: 'Philippines',
    flag: '🇵🇭',
  },
  {
    code: 'BRN',
    cca2: 'BN',
    name: 'Brunei',
    flag: '🇧🇳',
  },
  {
    code: 'CIV',
    cca2: 'CI',
    name: 'Ivory Coast',
    flag: '🇨🇮',
  },
  {
    code: 'GBR',
    cca2: 'GB',
    name: 'United Kingdom',
    flag: '🇬🇧',
  },
  {
    code: 'CAF',
    cca2: 'CF',
    name: 'Central African Republic',
    flag: '🇨🇫',
  },
  {
    code: 'KGZ',
    cca2: 'KG',
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
  },
  {
    code: 'SHN',
    cca2: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    flag: '🇸🇭',
  },
  {
    code: 'NAM',
    cca2: 'NA',
    name: 'Namibia',
    flag: '🇳🇦',
  },
  {
    code: 'GAB',
    cca2: 'GA',
    name: 'Gabon',
    flag: '🇬🇦',
  },
  {
    code: 'KEN',
    cca2: 'KE',
    name: 'Kenya',
    flag: '🇰🇪',
  },
  {
    code: 'GIB',
    cca2: 'GI',
    name: 'Gibraltar',
    flag: '🇬🇮',
  },
  {
    code: 'KAZ',
    cca2: 'KZ',
    name: 'Kazakhstan',
    flag: '🇰🇿',
  },
  {
    code: 'KOR',
    cca2: 'KR',
    name: 'South Korea',
    flag: '🇰🇷',
  },
  {
    code: 'PAK',
    cca2: 'PK',
    name: 'Pakistan',
    flag: '🇵🇰',
  },
  {
    code: 'SOM',
    cca2: 'SO',
    name: 'Somalia',
    flag: '🇸🇴',
  },
  {
    code: 'ZMB',
    cca2: 'ZM',
    name: 'Zambia',
    flag: '🇿🇲',
  },
  {
    code: 'MWI',
    cca2: 'MW',
    name: 'Malawi',
    flag: '🇲🇼',
  },
  {
    code: 'SSD',
    cca2: 'SS',
    name: 'South Sudan',
    flag: '🇸🇸',
  },
  {
    code: 'MAR',
    cca2: 'MA',
    name: 'Morocco',
    flag: '🇲🇦',
  },
  {
    code: 'TUV',
    cca2: 'TV',
    name: 'Tuvalu',
    flag: '🇹🇻',
  },
  {
    code: 'NGA',
    cca2: 'NG',
    name: 'Nigeria',
    flag: '🇳🇬',
  },
  {
    code: 'GRD',
    cca2: 'GD',
    name: 'Grenada',
    flag: '🇬🇩',
  },
  {
    code: 'MNE',
    cca2: 'ME',
    name: 'Montenegro',
    flag: '🇲🇪',
  },
  {
    code: 'UMI',
    cca2: 'UM',
    name: 'United States Minor Outlying Islands',
    flag: '🇺🇲',
  },
  {
    code: 'ETH',
    cca2: 'ET',
    name: 'Ethiopia',
    flag: '🇪🇹',
  },
  {
    code: 'ESP',
    cca2: 'ES',
    name: 'Spain',
    flag: '🇪🇸',
  },
  {
    code: 'BEN',
    cca2: 'BJ',
    name: 'Benin',
    flag: '🇧🇯',
  },
  {
    code: 'CAN',
    cca2: 'CA',
    name: 'Canada',
    flag: '🇨🇦',
  },
  {
    code: 'CPV',
    cca2: 'CV',
    name: 'Cape Verde',
    flag: '🇨🇻',
  },
  {
    code: 'ESH',
    cca2: 'EH',
    name: 'Western Sahara',
    flag: '🇪🇭',
  },
  {
    code: 'DZA',
    cca2: 'DZ',
    name: 'Algeria',
    flag: '🇩🇿',
  },
  {
    code: 'ALA',
    cca2: 'AX',
    name: 'Åland Islands',
    flag: '🇦🇽',
  },
  {
    code: 'PER',
    cca2: 'PE',
    name: 'Peru',
    flag: '🇵🇪',
  },
  {
    code: 'POL',
    cca2: 'PL',
    name: 'Poland',
    flag: '🇵🇱',
  },
  {
    code: 'VUT',
    cca2: 'VU',
    name: 'Vanuatu',
    flag: '🇻🇺',
  },
  {
    code: 'GNQ',
    cca2: 'GQ',
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
  },
  {
    code: 'BGR',
    cca2: 'BG',
    name: 'Bulgaria',
    flag: '🇧🇬',
  },
  {
    code: 'GUF',
    cca2: 'GF',
    name: 'French Guiana',
    flag: '🇬🇫',
  },
  {
    code: 'NER',
    cca2: 'NE',
    name: 'Niger',
    flag: '🇳🇪',
  },
  {
    code: 'IMN',
    cca2: 'IM',
    name: 'Isle of Man',
    flag: '🇮🇲',
  },
  {
    code: 'PLW',
    cca2: 'PW',
    name: 'Palau',
    flag: '🇵🇼',
  },
  {
    code: 'TZA',
    cca2: 'TZ',
    name: 'Tanzania',
    flag: '🇹🇿',
  },
  {
    code: 'NOR',
    cca2: 'NO',
    name: 'Norway',
    flag: '🇳🇴',
  },
  {
    code: 'BES',
    cca2: 'BQ',
    name: 'Caribbean Netherlands',
  },
  {
    code: 'GGY',
    cca2: 'GG',
    name: 'Guernsey',
    flag: '🇬🇬',
  },
  {
    code: 'UZB',
    cca2: 'UZ',
    name: 'Uzbekistan',
    flag: '🇺🇿',
  },
  {
    code: 'SJM',
    cca2: 'SJ',
    name: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
  },
  {
    code: 'TUR',
    cca2: 'TR',
    name: 'Turkey',
    flag: '🇹🇷',
  },
  {
    code: 'FJI',
    cca2: 'FJ',
    name: 'Fiji',
    flag: '🇫🇯',
  },
  {
    code: 'BLR',
    cca2: 'BY',
    name: 'Belarus',
    flag: '🇧🇾',
  },
  {
    code: 'GTM',
    cca2: 'GT',
    name: 'Guatemala',
    flag: '🇬🇹',
  },
  {
    code: 'URY',
    cca2: 'UY',
    name: 'Uruguay',
    flag: '🇺🇾',
  },
  {
    code: 'COK',
    cca2: 'CK',
    name: 'Cook Islands',
    flag: '🇨🇰',
  },
  {
    code: 'TWN',
    cca2: 'TW',
    name: 'Taiwan',
    flag: '🇹🇼',
  },
  {
    code: 'LSO',
    cca2: 'LS',
    name: 'Lesotho',
    flag: '🇱🇸',
  },
  {
    code: 'TLS',
    cca2: 'TL',
    name: 'Timor-Leste',
    flag: '🇹🇱',
  },
  {
    code: 'VEN',
    cca2: 'VE',
    name: 'Venezuela',
    flag: '🇻🇪',
  },
  {
    code: 'LKA',
    cca2: 'LK',
    name: 'Sri Lanka',
    flag: '🇱🇰',
  },
  {
    code: 'LBR',
    cca2: 'LR',
    name: 'Liberia',
    flag: '🇱🇷',
  },
  {
    code: 'NZL',
    cca2: 'NZ',
    name: 'New Zealand',
    flag: '🇳🇿',
  },
  {
    code: 'TKL',
    cca2: 'TK',
    name: 'Tokelau',
    flag: '🇹🇰',
  },
  {
    code: 'VIR',
    cca2: 'VI',
    name: 'United States Virgin Islands',
    flag: '🇻🇮',
  },
  {
    code: 'CUB',
    cca2: 'CU',
    name: 'Cuba',
    flag: '🇨🇺',
  },
  {
    code: 'TCD',
    cca2: 'TD',
    name: 'Chad',
    flag: '🇹🇩',
  },
  {
    code: 'COM',
    cca2: 'KM',
    name: 'Comoros',
    flag: '🇰🇲',
  },
  {
    code: 'KWT',
    cca2: 'KW',
    name: 'Kuwait',
    flag: '🇰🇼',
  },
  {
    code: 'VCT',
    cca2: 'VC',
    name: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
  },
  {
    code: 'BMU',
    cca2: 'BM',
    name: 'Bermuda',
    flag: '🇧🇲',
  },
  {
    code: 'PSE',
    cca2: 'PS',
    name: 'Palestine',
    flag: '🇵🇸',
  },
  {
    code: 'VAT',
    cca2: 'VA',
    name: 'Vatican City',
    flag: '🇻🇦',
  },
  {
    code: 'MLI',
    cca2: 'ML',
    name: 'Mali',
    flag: '🇲🇱',
  },
  {
    code: 'PNG',
    cca2: 'PG',
    name: 'Papua New Guinea',
    flag: '🇵🇬',
  },
  {
    code: 'SWZ',
    cca2: 'SZ',
    name: 'Eswatini',
    flag: '🇸🇿',
  },
  {
    code: 'VNM',
    cca2: 'VN',
    name: 'Vietnam',
    flag: '🇻🇳',
  },
  {
    code: 'MNG',
    cca2: 'MN',
    name: 'Mongolia',
    flag: '🇲🇳',
  },
  {
    code: 'USA',
    cca2: 'US',
    name: 'United States',
    flag: '🇺🇸',
  },
  {
    code: 'YEM',
    cca2: 'YE',
    name: 'Yemen',
    flag: '🇾🇪',
  },
  {
    code: 'MAC',
    cca2: 'MO',
    name: 'Macau',
    flag: '🇲🇴',
  },
  {
    code: 'BHS',
    cca2: 'BS',
    name: 'Bahamas',
    flag: '🇧🇸',
  },
  {
    code: 'SAU',
    cca2: 'SA',
    name: 'Saudi Arabia',
    flag: '🇸🇦',
  },
  {
    code: 'CYP',
    cca2: 'CY',
    name: 'Cyprus',
    flag: '🇨🇾',
  },
  {
    code: 'LAO',
    cca2: 'LA',
    name: 'Laos',
    flag: '🇱🇦',
  },
  {
    code: 'PRI',
    cca2: 'PR',
    name: 'Puerto Rico',
    flag: '🇵🇷',
  },
  {
    code: 'EST',
    cca2: 'EE',
    name: 'Estonia',
    flag: '🇪🇪',
  },
  {
    code: 'MYT',
    cca2: 'YT',
    name: 'Mayotte',
    flag: '🇾🇹',
  },
  {
    code: 'MMR',
    cca2: 'MM',
    name: 'Myanmar',
    flag: '🇲🇲',
  },
  {
    code: 'VGB',
    cca2: 'VG',
    name: 'British Virgin Islands',
    flag: '🇻🇬',
  },
  {
    code: 'HRV',
    cca2: 'HR',
    name: 'Croatia',
    flag: '🇭🇷',
  },
  {
    code: 'BLZ',
    cca2: 'BZ',
    name: 'Belize',
    flag: '🇧🇿',
  },
  {
    code: 'MKD',
    cca2: 'MK',
    name: 'North Macedonia',
    flag: '🇲🇰',
  },
  {
    code: 'SRB',
    cca2: 'RS',
    name: 'Serbia',
    flag: '🇷🇸',
  },
  {
    code: 'SLB',
    cca2: 'SB',
    name: 'Solomon Islands',
    flag: '🇸🇧',
  },
  {
    code: 'NFK',
    cca2: 'NF',
    name: 'Norfolk Island',
    flag: '🇳🇫',
  },
  {
    code: 'LBN',
    cca2: 'LB',
    name: 'Lebanon',
    flag: '🇱🇧',
  },
  {
    code: 'NRU',
    cca2: 'NR',
    name: 'Nauru',
    flag: '🇳🇷',
  },
  {
    code: 'IRL',
    cca2: 'IE',
    name: 'Ireland',
    flag: '🇮🇪',
  },
  {
    code: 'IRN',
    cca2: 'IR',
    name: 'Iran',
    flag: '🇮🇷',
  },
  {
    code: 'BIH',
    cca2: 'BA',
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
  },
  {
    code: 'HND',
    cca2: 'HN',
    name: 'Honduras',
    flag: '🇭🇳',
  },
  {
    code: 'TJK',
    cca2: 'TJ',
    name: 'Tajikistan',
    flag: '🇹🇯',
  },
  {
    code: 'CRI',
    cca2: 'CR',
    name: 'Costa Rica',
    flag: '🇨🇷',
  },
  {
    code: 'FRA',
    cca2: 'FR',
    name: 'France',
    flag: '🇫🇷',
  },
  {
    code: 'MDA',
    cca2: 'MD',
    name: 'Moldova',
    flag: '🇲🇩',
  },
  {
    code: 'CHE',
    cca2: 'CH',
    name: 'Switzerland',
    flag: '🇨🇭',
  },
  {
    code: 'CZE',
    cca2: 'CZ',
    name: 'Czechia',
    flag: '🇨🇿',
  },
  {
    code: 'BRA',
    cca2: 'BR',
    name: 'Brazil',
    flag: '🇧🇷',
  },
  {
    code: 'BWA',
    cca2: 'BW',
    name: 'Botswana',
    flag: '🇧🇼',
  },
  {
    code: 'COG',
    cca2: 'CG',
    name: 'Republic of the Congo',
    flag: '🇨🇬',
  },
  {
    code: 'BDI',
    cca2: 'BI',
    name: 'Burundi',
    flag: '🇧🇮',
  },
  {
    code: 'SLV',
    cca2: 'SV',
    name: 'El Salvador',
    flag: '🇸🇻',
  },
  {
    code: 'MLT',
    cca2: 'MT',
    name: 'Malta',
    flag: '🇲🇹',
  },
  {
    code: 'SMR',
    cca2: 'SM',
    name: 'San Marino',
    flag: '🇸🇲',
  },
  {
    code: 'GUY',
    cca2: 'GY',
    name: 'Guyana',
    flag: '🇬🇾',
  },
  {
    code: 'KNA',
    cca2: 'KN',
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
  },
  {
    code: 'NLD',
    cca2: 'NL',
    name: 'Netherlands',
    flag: '🇳🇱',
  },
  {
    code: 'SYR',
    cca2: 'SY',
    name: 'Syria',
    flag: '🇸🇾',
  },
  {
    code: 'BFA',
    cca2: 'BF',
    name: 'Burkina Faso',
    flag: '🇧🇫',
  },
  {
    code: 'WLF',
    cca2: 'WF',
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
  },
  {
    code: 'HUN',
    cca2: 'HU',
    name: 'Hungary',
    flag: '🇭🇺',
  },
  {
    code: 'WSM',
    cca2: 'WS',
    name: 'Samoa',
    flag: '🇼🇸',
  },
  {
    code: 'TUN',
    cca2: 'TN',
    name: 'Tunisia',
    flag: '🇹🇳',
  },
  {
    code: 'JAM',
    cca2: 'JM',
    name: 'Jamaica',
    flag: '🇯🇲',
  },
  {
    code: 'PRK',
    cca2: 'KP',
    name: 'North Korea',
    flag: '🇰🇵',
  },
  {
    code: 'ASM',
    cca2: 'AS',
    name: 'American Samoa',
    flag: '🇦🇸',
  },
  {
    code: 'COL',
    cca2: 'CO',
    name: 'Colombia',
    flag: '🇨🇴',
  },
  {
    code: 'AND',
    cca2: 'AD',
    name: 'Andorra',
    flag: '🇦🇩',
  },
  {
    code: 'GNB',
    cca2: 'GW',
    name: 'Guinea-Bissau',
    flag: '🇬🇼',
  },
  {
    code: 'LTU',
    cca2: 'LT',
    name: 'Lithuania',
    flag: '🇱🇹',
  },
  {
    code: 'OMN',
    cca2: 'OM',
    name: 'Oman',
    flag: '🇴🇲',
  },
  {
    code: 'LUX',
    cca2: 'LU',
    name: 'Luxembourg',
    flag: '🇱🇺',
  },
  {
    code: 'MOZ',
    cca2: 'MZ',
    name: 'Mozambique',
    flag: '🇲🇿',
  },
  {
    code: 'PRT',
    cca2: 'PT',
    name: 'Portugal',
    flag: '🇵🇹',
  },
  {
    code: 'BOL',
    cca2: 'BO',
    name: 'Bolivia',
    flag: '🇧🇴',
  },
  {
    code: 'UGA',
    cca2: 'UG',
    name: 'Uganda',
    flag: '🇺🇬',
  },
  {
    code: 'HTI',
    cca2: 'HT',
    name: 'Haiti',
    flag: '🇭🇹',
  },
  {
    code: 'STP',
    cca2: 'ST',
    name: 'São Tomé and Príncipe',
    flag: '🇸🇹',
  },
  {
    code: 'IND',
    cca2: 'IN',
    name: 'India',
    flag: '🇮🇳',
  },
  {
    code: 'NCL',
    cca2: 'NC',
    name: 'New Caledonia',
    flag: '🇳🇨',
  },
  {
    code: 'PRY',
    cca2: 'PY',
    name: 'Paraguay',
    flag: '🇵🇾',
  },
  {
    code: 'SXM',
    cca2: 'SX',
    name: 'Sint Maarten',
    flag: '🇸🇽',
  },
  {
    code: 'SDN',
    cca2: 'SD',
    name: 'Sudan',
    flag: '🇸🇩',
  },
  {
    code: 'SPM',
    cca2: 'PM',
    name: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
  },
  {
    code: 'ISL',
    cca2: 'IS',
    name: 'Iceland',
    flag: '🇮🇸',
  },
  {
    code: 'DJI',
    cca2: 'DJ',
    name: 'Djibouti',
    flag: '🇩🇯',
  },
  {
    code: 'ATG',
    cca2: 'AG',
    name: 'Antigua and Barbuda',
    flag: '🇦🇬',
  },
  {
    code: 'ARE',
    cca2: 'AE',
    name: 'United Arab Emirates',
    flag: '🇦🇪',
  },
  {
    code: 'SEN',
    cca2: 'SN',
    name: 'Senegal',
    flag: '🇸🇳',
  },
  {
    code: 'IRQ',
    cca2: 'IQ',
    name: 'Iraq',
    flag: '🇮🇶',
  },
  {
    code: 'ZWE',
    cca2: 'ZW',
    name: 'Zimbabwe',
    flag: '🇿🇼',
  },
  {
    code: 'MEX',
    cca2: 'MX',
    name: 'Mexico',
    flag: '🇲🇽',
  },
  {
    code: 'PCN',
    cca2: 'PN',
    name: 'Pitcairn Islands',
    flag: '🇵🇳',
  },
  {
    code: 'MUS',
    cca2: 'MU',
    name: 'Mauritius',
    flag: '🇲🇺',
  },
  {
    code: 'RUS',
    cca2: 'RU',
    name: 'Russia',
    flag: '🇷🇺',
  },
  {
    code: 'TON',
    cca2: 'TO',
    name: 'Tonga',
    flag: '🇹🇴',
  },
  {
    code: 'SUR',
    cca2: 'SR',
    name: 'Suriname',
    flag: '🇸🇷',
  },
  {
    code: 'TGO',
    cca2: 'TG',
    name: 'Togo',
    flag: '🇹🇬',
  },
  {
    code: 'MSR',
    cca2: 'MS',
    name: 'Montserrat',
    flag: '🇲🇸',
  },
  {
    code: 'MCO',
    cca2: 'MC',
    name: 'Monaco',
    flag: '🇲🇨',
  },
  {
    code: 'ECU',
    cca2: 'EC',
    name: 'Ecuador',
    flag: '🇪🇨',
  },
  {
    code: 'PAN',
    cca2: 'PA',
    name: 'Panama',
    flag: '🇵🇦',
  },
  {
    code: 'RWA',
    cca2: 'RW',
    name: 'Rwanda',
    flag: '🇷🇼',
  },
  {
    code: 'TTO',
    cca2: 'TT',
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
  },
  {
    code: 'IOT',
    cca2: 'IO',
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
  },
  {
    code: 'MYS',
    cca2: 'MY',
    name: 'Malaysia',
    flag: '🇲🇾',
  },
  {
    code: 'SWE',
    cca2: 'SE',
    name: 'Sweden',
    flag: '🇸🇪',
  },
  {
    code: 'MAF',
    cca2: 'MF',
    name: 'Saint Martin',
    flag: '🇲🇫',
  },
  {
    code: 'ALB',
    cca2: 'AL',
    name: 'Albania',
    flag: '🇦🇱',
  },
  {
    code: 'BVT',
    cca2: 'BV',
    name: 'Bouvet Island',
    flag: '🇧🇻',
  },
  {
    code: 'DEU',
    cca2: 'DE',
    name: 'Germany',
    flag: '🇩🇪',
  },
  {
    code: 'ZAF',
    cca2: 'ZA',
    name: 'South Africa',
    flag: '🇿🇦',
  },
  {
    code: 'FSM',
    cca2: 'FM',
    name: 'Micronesia',
    flag: '🇫🇲',
  },
  {
    code: 'SYC',
    cca2: 'SC',
    name: 'Seychelles',
    flag: '🇸🇨',
  },
  {
    code: 'GRC',
    cca2: 'GR',
    name: 'Greece',
    flag: '🇬🇷',
  },
  {
    code: 'CXR',
    cca2: 'CX',
    name: 'Christmas Island',
    flag: '🇨🇽',
  },
  {
    code: 'MNP',
    cca2: 'MP',
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
  },
  {
    code: 'SVN',
    cca2: 'SI',
    name: 'Slovenia',
    flag: '🇸🇮',
  },
  {
    code: 'IDN',
    cca2: 'ID',
    name: 'Indonesia',
    flag: '🇮🇩',
  },
  {
    code: 'SGP',
    cca2: 'SG',
    name: 'Singapore',
    flag: '🇸🇬',
  },
  {
    code: 'BGD',
    cca2: 'BD',
    name: 'Bangladesh',
    flag: '🇧🇩',
  },
  {
    code: 'ATA',
    cca2: 'AQ',
    name: 'Antarctica',
    flag: '🇦🇶',
  },
  {
    code: 'EGY',
    cca2: 'EG',
    name: 'Egypt',
    flag: '🇪🇬',
  },
  {
    code: 'BEL',
    cca2: 'BE',
    name: 'Belgium',
    flag: '🇧🇪',
  },
  {
    code: 'LIE',
    cca2: 'LI',
    name: 'Liechtenstein',
    flag: '🇱🇮',
  },
  {
    code: 'MHL',
    cca2: 'MH',
    name: 'Marshall Islands',
    flag: '🇲🇭',
  },
  {
    code: 'BRB',
    cca2: 'BB',
    name: 'Barbados',
    flag: '🇧🇧',
  },
  {
    code: 'MDV',
    cca2: 'MV',
    name: 'Maldives',
    flag: '🇲🇻',
  },
  {
    code: 'COD',
    cca2: 'CD',
    name: 'DR Congo',
    flag: '🇨🇩',
  },
  {
    code: 'AUS',
    cca2: 'AU',
    name: 'Australia',
    flag: '🇦🇺',
  },
  {
    code: 'AUT',
    cca2: 'AT',
    name: 'Austria',
    flag: '🇦🇹',
  },
  {
    code: 'CMR',
    cca2: 'CM',
    name: 'Cameroon',
    flag: '🇨🇲',
  },
  {
    code: 'ATF',
    cca2: 'TF',
    name: 'French Southern and Antarctic Lands',
    flag: '🇹🇫',
  },
  {
    code: 'ABW',
    cca2: 'AW',
    name: 'Aruba',
    flag: '🇦🇼',
  },
  {
    code: 'PYF',
    cca2: 'PF',
    name: 'French Polynesia',
    flag: '🇵🇫',
  },
  {
    code: 'BLM',
    cca2: 'BL',
    name: 'Saint Barthélemy',
    flag: '🇧🇱',
  },
  {
    code: 'GMB',
    cca2: 'GM',
    name: 'Gambia',
    flag: '🇬🇲',
  },
  {
    code: 'AIA',
    cca2: 'AI',
    name: 'Anguilla',
    flag: '🇦🇮',
  },
  {
    code: 'CUW',
    cca2: 'CW',
    name: 'Curaçao',
    flag: '🇨🇼',
  },
  {
    code: 'HKG',
    cca2: 'HK',
    name: 'Hong Kong',
    flag: '🇭🇰',
  },
  {
    code: 'BTN',
    cca2: 'BT',
    name: 'Bhutan',
    flag: '🇧🇹',
  },
  {
    code: 'NIC',
    cca2: 'NI',
    name: 'Nicaragua',
    flag: '🇳🇮',
  },
  {
    code: 'CCK',
    cca2: 'CC',
    name: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
  },
  {
    code: 'CHL',
    cca2: 'CL',
    name: 'Chile',
    flag: '🇨🇱',
  },
  {
    code: 'NIU',
    cca2: 'NU',
    name: 'Niue',
    flag: '🇳🇺',
  },
  {
    code: 'JOR',
    cca2: 'JO',
    name: 'Jordan',
    flag: '🇯🇴',
  },
  {
    code: 'AFG',
    cca2: 'AF',
    name: 'Afghanistan',
    flag: '🇦🇫',
  },
  {
    code: 'FRO',
    cca2: 'FO',
    name: 'Faroe Islands',
    flag: '🇫🇴',
  },
  {
    code: 'DMA',
    cca2: 'DM',
    name: 'Dominica',
    flag: '🇩🇲',
  },
];

export { countries };
