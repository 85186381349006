import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useCartIdUpdater } from '$src/hooks/client/useCartIdUpdater';
import { useCart } from '$src/hooks/queries';
import { gateway } from '$src/http';
import { UpdateLineItemsInput } from '$src/pages/api/[shopId]/[cartId]/update-line-items';

const MUTATION_CART_UPDATE_LINE_ITEMS_KEY = 'MUTATION/UPDATE_LINE_ITEMS';

export const useUpdateLineItems = () => {
  const baseUrl = useBaseApiUrl();
  const toast = useToast();
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const updateCartId = useCartIdUpdater();
  const { data: cart } = useCart();

  return useMutation({
    mutationFn: (data: UpdateLineItemsInput) => gateway.post<Cart>(`${baseUrl}/update-line-items`, data),
    mutationKey: [MUTATION_CART_UPDATE_LINE_ITEMS_KEY, baseUrl],
    onMutate: async ({ lineItems }) => {
      const previousCart = queryClient.getQueryData<Cart>([CART_QUERY_KEY, cart?.id]);
      const newLineItems =
        previousCart?.lineItems?.filter((lineItem) =>
          lineItems.some(
            (item) => item.id !== lineItem.id || (item.id === lineItem.id && item.quantity !== 0),
          ),
        ) ?? [];
      const newCart = { ...previousCart, lineItems: newLineItems };
      // Optimistically update to the new value
      // @ts-expect-error - known
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, newCart.id], newCart);

      // Return a context object with the snapshotted value
      return { previousCart };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onSuccess: ({ data }) => {
      updateCartId(data.id);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
    },
    onError: (err, data, context) => {
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, context?.previousCart?.id], context?.previousCart);
      toast({
        description: t('errors.serverError'),
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });
};
