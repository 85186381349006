import { Stack, Text } from '@chakra-ui/react';
import { JustToast } from '@getjust/leaf';
import { useTranslation } from 'react-i18next';

import { useErrorAtom } from '$src/hooks/state/useErrorAtom';

export const ToastError = () => {
  const { t } = useTranslation();
  const { error, onErrorChange } = useErrorAtom();

  return (
    <JustToast onBack={onErrorChange} back={t('buttons.back')} isOpen={Boolean(error)}>
      <Stack alignContent="left" justifyContent="left">
        <Text
          color="#1C1C1C"
          fontSize="2xl"
          textAlign="left"
          fontWeight="medium"
          lineHeight="normal"
          alignItems="flex-start"
          p="0"
        >
          {error?.title}
        </Text>
        <Text
          fontSize="md"
          fontWeight="350"
          color="#1C1C1C"
          textAlign="left"
          lineHeight="4"
          w="full"
          alignItems="flex-start"
          p="0"
        >
          {error?.sentence}
        </Text>
      </Stack>
    </JustToast>
  );
};
