import { atom } from 'jotai';
import { z } from 'zod';

const KeyValueSchema = z.record(
  z.union([z.string(), z.number(), z.undefined(), z.array(z.string()), z.array(z.number())]),
);

export const ZAmplitudeEventAtom = z.object({
  name: z.string(),
  props: KeyValueSchema.optional(),
});
export const ZAmplitudeEventQueueAtom = z.array(
  z.object({
    name: z.string(),
    props: KeyValueSchema.optional(),
  }),
);
export const ZAmplitudeEventCountsAtom = z.record(z.number());

export type AmplitudeEventAtom = z.infer<typeof ZAmplitudeEventAtom>;
export type AmplitudeEventQueueAtom = z.infer<typeof ZAmplitudeEventQueueAtom>;
export type AmplitudeEventCountsAtom = z.infer<typeof ZAmplitudeEventCountsAtom>;

export const amplitudeEventQueueAtom = atom<AmplitudeEventQueueAtom>([]);
export const amplitudeEventCountsAtom = atom<AmplitudeEventCountsAtom>({});
