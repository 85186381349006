const Coupon = ({ fill }: { fill: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 17.5769C22 17.8757 21.8812 18.1624 21.6701 18.3738L18.3736 21.6703C18.1622 21.8814 17.8757 22 17.5769 22C17.2782 22 16.9914 21.8812 16.7801 21.6701L10.2413 15.1313C10.1578 15.0501 10.0931 14.9516 10.0518 14.8427C10.0097 14.7316 9.99291 14.6125 10.0027 14.4942C10.0037 14.4827 10.005 14.4713 10.0068 14.46L10.5038 11.2332C10.5208 11.0458 10.6029 10.8699 10.7364 10.7364C10.8699 10.6029 11.0458 10.5208 11.2332 10.5038L14.46 10.0068C14.4713 10.005 14.4827 10.0037 14.4942 10.0027C14.6125 9.99291 14.7316 10.0097 14.8427 10.0518C14.9516 10.0931 15.0501 10.1578 15.1313 10.2413L21.6703 16.7803C21.8814 16.9916 22 17.2782 22 17.5769ZM12.6364 13.5519C12.6364 13.0463 13.0463 12.6364 13.5519 12.6364C14.0575 12.6364 14.4674 13.0463 14.4674 13.5519C14.4674 14.0575 14.0575 14.4674 13.5519 14.4674C13.0463 14.4674 12.6364 14.0575 12.6364 13.5519Z"
        fill={fill}
      />
    </svg>
  );
};

export default Coupon;
