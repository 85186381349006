import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CheckoutV1 } from '@getjust/api-schema';

import { MUTATION_UPDATE_PROFILE, USER_INFO_QUERY_KEY } from '$src/constants';
import { justAPI } from '$src/http';

export interface UserUpdatePayload extends CheckoutV1.AuthUpdateProfileRequestBody {
  updateCheckoutShippingMethods?: boolean;
}

export function useProfileUpdate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (user: UserUpdatePayload) => justAPI.put('/v1/profile', user),
    mutationKey: [MUTATION_UPDATE_PROFILE],
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [USER_INFO_QUERY_KEY] });
    },
  });
}
