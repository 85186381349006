import { atom } from 'jotai';
import { z } from 'zod';

import type { ZPaymentStatusSchema } from '$src/models/state/payment-status';

export type PaymentStatusAtom = z.infer<typeof ZPaymentStatusSchema>;

export const paymentStatusAtom = atom<PaymentStatusAtom>({
  display: false,
});
