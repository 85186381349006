import { AuthCustomer } from '@getjust/api-schema';
import { useMutation } from '@tanstack/react-query';

import { useCustomRouter } from '$hooks/client';
import { useEmailAtom, useLoginAtom } from '$hooks/state';
import { USE_AUTH_LEGACY } from '$src/constants';
import { useAuth } from '$src/hooks/auth';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useShowChallengeAtom } from '$src/hooks/state/useShowChallengeAtom';
import { authAPI } from '$src/http';

const MUTATION_LOGIN_CHALLENGE_KEY = 'MUTATION/LOGIN_CHALLENGE';

export const useLoginChallenge = () => {
  const router = useCustomRouter();
  const { onEmailChange } = useEmailAtom();
  const { loginInfo, onLoginChange } = useLoginAtom();
  const { track } = useTrackAmplitude();
  const { setIsAuthenticated, setLegacyState } = useAuth();
  const { close } = useShowChallengeAtom();

  const mutation = useMutation({
    mutationFn: (code: string) =>
      authAPI.post<AuthCustomer.AuthChallengeResponseBody>('/challenge', {
        userId: loginInfo!.userId!,
        sessionId: loginInfo!.sessionId!,
        code,
        clientId: 'web',
      }),
    mutationKey: [MUTATION_LOGIN_CHALLENGE_KEY],
    onError: async (error: any) => {
      track('OTP failed');
      if (error.response.data.sessionId) {
        onLoginChange({ ...loginInfo, sessionId: error.response.data.sessionId });
      }
    },
    onSuccess: ({ data }) => {
      track('OTP succeeded');
      track('Customer logged in');
      if (USE_AUTH_LEGACY && data.outcome === 'loggedIn') {
        setLegacyState({ accessToken: data.accessToken ?? '', refreshToken: data.refreshToken ?? '' });
      }
      setIsAuthenticated(true);
      onEmailChange();
      close();
      router.push('/authenticated');
    },
  });

  return {
    onChallenge: mutation.mutateAsync,
    status: mutation.status,
  };
};
