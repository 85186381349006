import { useAtom } from 'jotai';

import { ErrorAtom, ZError, errorAtom } from '$src/business/atoms/error';

export function useErrorAtom() {
  const [error, setError] = useAtom(errorAtom);

  const onErrorChange = (nextValue?: ErrorAtom) => {
    const parse = ZError.safeParse(nextValue);
    if (parse.success) {
      setError(parse.data);
    } else {
      console.error('Error while change toast error', {
        error: parse.error,
        hook: useErrorAtom.name,
      });
    }
  };

  return {
    error,
    onErrorChange,
  };
}
