export function formatTextFirstLetterInCaps(input?: string): string {
  const trimmedInput = input?.trim();
  if (!trimmedInput || trimmedInput?.length === 0) {
    return '';
  }
  return trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1).toLowerCase();
}

export function uppercaseFirstLetterOfEachWord(sentence: string): string {
  return sentence
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
