import { atom } from 'jotai';
import z from 'zod';

export const ZEstimatedShipping = z.object({
  id: z.string(),
  title: z.string(),
  amount: z.number(),
  currency: z.string(),
});

export type EstimatedShippingAtom = z.infer<typeof ZEstimatedShipping>;

export const estimatedShippingAtom = atom<z.infer<typeof ZEstimatedShipping> | undefined>(undefined);
