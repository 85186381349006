import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useCartIdUpdater } from '$src/hooks/client/useCartIdUpdater';
import { useEstimatedShipping } from '$src/hooks/mutations/useEstimatedShipping';
import { useUserLocationDetection } from '$src/hooks/queries';
import { useROFormMessageAtom } from '$src/hooks/state';
import { gateway } from '$src/http';
import { DeleteDiscountInput } from '$src/pages/api/[shopId]/[cartId]/delete-discount';

const MUTATION_DELETE_DISCOUNT_KEY = 'MUTATION/DELETE_DISCOUNT';

export const useDeleteDiscount = () => {
  const baseUrl = useBaseApiUrl();
  const queryClient = useQueryClient();
  const updateCartId = useCartIdUpdater();
  const message = useROFormMessageAtom();
  const { data: location } = useUserLocationDetection();
  const { mutateAsync: estimateShipping, isPending: isEstimating } = useEstimatedShipping();

  return useMutation({
    mutationFn: (discount: DeleteDiscountInput) => gateway.post<Cart>(`${baseUrl}/delete-discount`, discount),
    mutationKey: [MUTATION_DELETE_DISCOUNT_KEY, baseUrl],
    onSuccess: ({ data }) => {
      updateCartId(data.id);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);

      if (
        message?.cart.lineItems &&
        message?.cart.lineItems.length !== 0 &&
        location?.location &&
        location?.location?.latitude !== 0 &&
        !isEstimating
      ) {
        estimateShipping({
          cart: message.cart,
          longitude: location.location.longitude,
          latitude: location.location.latitude,
        });
      }
    },
  });
};
