import { useCallback } from 'react';

import { useFormMessageAtom } from '$hooks/state';
import { OutboundFormMessage } from '$src/models/state/message';
import { _postMessage } from '$src/utils';

export function usePostMessage() {
  const { formMessage } = useFormMessageAtom();

  const postMessage = useCallback(
    (message: Partial<OutboundFormMessage>) => {
      _postMessage({
        ...message,
        sessionKey: formMessage?.sessionKey,
      });
    },
    [formMessage],
  );

  return {
    postMessage,
  };
}
