import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useSaveTempUser } from '$src/hooks/client/useSaveTempUser';
import { useCountriesFromCache } from '$src/hooks/queries/useCountries';
import { InputsForm } from '$src/types';

export const useCountryCodeContextFromLocalStorage = () => {
  const { control } = useForm<InputsForm>();
  const countries = useCountriesFromCache();
  const { values: localStorageValues } = useSaveTempUser(control);

  const countryCodeContext = useMemo(() => {
    const countryCodeContext =
      localStorageValues?.address?.country_code ??
      countries.find((country) => country.country === localStorageValues?.address?.country)?.value;
    if (countryCodeContext?.length !== 0) {
      return countryCodeContext;
    }
    return undefined;
  }, [localStorageValues?.address?.country_code, localStorageValues?.address?.country, countries]);

  return countryCodeContext;
};
