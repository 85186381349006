import { atom } from 'jotai';
import z from 'zod';

export const ZError = z.union([
  z.object({
    title: z.string(),
    sentence: z.string(),
  }),
  z.undefined(),
]);

export type ErrorAtom = z.infer<typeof ZError>;

export const errorAtom = atom<z.infer<typeof ZError> | undefined>(undefined);
