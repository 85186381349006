import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'next-i18next';

import StarLoadingAnimation from '$animations/starLoading.json';
import SmallJust from '$components/SvgLogos/SmallJust';
import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { useShop } from '$src/hooks/queries';

export const PaidLoader = () => {
  const { t } = useTranslation();
  const { data: shop } = useShop();
  const { isMobile } = useIsDesktop();
  return (
    <Box
      position="absolute"
      as="main"
      flex="1 1 auto"
      overflow="auto"
      p="0"
      m="0"
      height="full"
      width="full"
      display="flex"
      backgroundColor="rgba(0, 0, 0, 0.60) !important"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      alignSelf="center"
      zIndex="10000"
    >
      <Stack
        display="flex"
        alignItems="center"
        alignContent="center"
        alignSelf="center"
        backgroundColor="#FFF"
        w={isMobile ? '21.56rem' : '32.5rem'}
        h={isMobile ? '16rem' : '23.5rem'}
        borderRadius="1.5rem"
        border="1px #8DE690"
        filter="drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.10))"
        pt={isMobile ? '2rem' : '5.75rem'}
        gap="0"
      >
        <Player
          src={StarLoadingAnimation}
          autoplay
          loop
          speed={1}
          style={{ height: '4rem', marginBottom: '1rem' }}
        >
          <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>
        <Text color="#1C1C1C" textAlign="center" fontWeight="medium" lineHeight="6">
          {t('loader.processing')}
        </Text>
        <Text color="#1C1C1C" textAlign="center" fontWeight="medium" lineHeight="6">
          {t('loader.dontRefresh')}
        </Text>

        {shop?.name !== undefined && (
          <HStack spacing={1} position="absolute" bottom="4">
            <SmallJust />
            <Text color="#8F8F8F" textAlign="center" fontSize="xs" fontWeight="350" lineHeight="md">
              {t('loader.authorizedBy')} {shop.name}
            </Text>
          </HStack>
        )}
      </Stack>
    </Box>
  );
};
