import { useAtom, useAtomValue } from 'jotai';

import { CountryCodeAtom, countryCodeAtom } from '$src/business/atoms/country-code';
import { countries } from '$src/hooks/state/useCountryCodeAtom/countries';
import { setXCountryGatewayHeader } from '$src/http';

export function useCountryCodeAtom() {
  const [country, setCountry] = useAtom(countryCodeAtom);

  const onCountryCodeChange = (nextValue?: CountryCodeAtom) => {
    if (!nextValue) {
      setCountry(undefined);
      return;
    }

    setXCountryGatewayHeader(nextValue);

    setCountry(nextValue);
  };

  const changeCountry = (countryName?: string) => {
    const countryCode =
      countries.find((country) => country.name.toLowerCase() === countryName?.toLowerCase()) ?? 'FR';
    // @ts-expect-error on country change
    onCountryCodeChange(countryCode?.cca2);
  };

  return {
    country,
    onCountryCodeChange,
    changeCountry,
  };
}

export function useROCountryCodeAtom() {
  return useAtomValue(countryCodeAtom);
}
