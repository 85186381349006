import { useJustDrawer } from '@getjust/leaf';
import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { showCartAtom } from '$business/atoms/show-cart';

export function useShowCartAtom(): {
  showCart: boolean;
  close: () => void;
  open: () => void;
  toggle: () => void;
} {
  const [showCart, setShowCart] = useAtom(showCartAtom);
  const { onToggle, onClose, onOpen } = useJustDrawer();

  const toggle = useCallback(() => {
    setShowCart(!showCart);
    onToggle();
  }, [showCart, setShowCart, onToggle]);

  const close = useCallback(() => {
    setShowCart(false);
    onClose();
  }, [setShowCart, onClose]);

  const open = useCallback(() => {
    setShowCart(true);
    onOpen();
  }, [setShowCart, onOpen]);

  return { showCart, close, open, toggle };
}
