import { useIsMutating } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import { UPDATE_SHIPPING_METHOD_MUTATION_KEY } from '$hooks/mutations';
import { useSetPickUpPoint } from '$hooks/mutations/useSetPickUpPoint';
import { useCart } from '$hooks/queries';
import { usePickupPoints } from '$hooks/queries/usePickupPoints';

/**
 * This hook selects automatically the closer pickup point when a pickup shipping method is selected.
 * Just call this hook at top of the application, it's autonomous
 */
export const useAutoSelectPickupPoint = () => {
  const { mutateAsync } = useSetPickUpPoint();
  const { data: cart } = useCart();
  const [autoselectDone, setAutoselectDone] = useState(false);

  const isMutatingShippingMethods = useIsMutating({ mutationKey: [UPDATE_SHIPPING_METHOD_MUTATION_KEY] });

  const selectedShippingMethodCarrier = useMemo(
    () =>
      cart?.shipping?.selected?.[0]?.carriers?.[0]?.pickup
        ? cart?.shipping?.selected?.[0]?.carriers?.[0]
        : null,
    [cart?.shipping?.selected],
  );

  const search = useMemo(
    () => ({
      address1: cart?.shipping?.address?.address1 ?? '',
      country: cart?.shipping?.address?.countryCode ?? '',
      zip: cart?.shipping?.address?.zip ?? '',
      city: cart?.shipping?.address?.city ?? '',
    }),
    [cart?.shipping?.address],
  );

  useEffect(() => {
    // if search (and so address) did change, we want to reselect the new first pickupPoint
    setAutoselectDone(false);
  }, [search, selectedShippingMethodCarrier?.id]);

  const { data, status } = usePickupPoints(selectedShippingMethodCarrier?.pickup, search);

  useEffect(() => {
    if (
      isMutatingShippingMethods === 0 &&
      cart?.shipping?.selected?.[0]?.id &&
      !autoselectDone &&
      selectedShippingMethodCarrier?.pickup &&
      status !== 'pending' &&
      data?.[0]
    ) {
      // eslint-disable-next-line no-console
      console.log('[AUTOSELECTPIKCUPPOINT] Setting closer pickup point automatically...');
      mutateAsync({
        pickupPoint: data[0],
        shippingMethodId: cart.shipping.selected[0].id,
      });
      setAutoselectDone(true);
    }
  }, [
    autoselectDone,
    cart?.shipping.selected,
    data,
    isMutatingShippingMethods,
    mutateAsync,
    selectedShippingMethodCarrier?.pickup,
    status,
  ]);
};
