import { Box } from '@chakra-ui/react';

import { FormLoader } from '$components/FormLoader';
import { PaidLoader } from '$components/PaidLoader';
import { ToastError } from '$components/ToastError';

interface LoadingWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
  isPaidLoading: boolean;
}

export const LoadingWrapper = ({ isLoading, isPaidLoading, children }: LoadingWrapperProps) => {
  return (
    <>
      {isLoading && <FormLoader />}
      {isPaidLoading && <PaidLoader />}
      <ToastError />
      <Box
        display={isLoading ? 'none' : 'block'}
        overflowY={isLoading ? 'hidden' : 'auto'}
        h="full"
        backgroundColor="white"
      >
        {children}
      </Box>
    </>
  );
};
