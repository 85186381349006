import { CheckoutV1 } from '@getjust/api-schema';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UPDATE_SOURCES_MUTATION_KEY, USER_INFO_QUERY_KEY } from '$src/constants';
import { justAPI } from '$src/http';
import { ReshapedUser } from '$src/types';

export const useUpdateSource = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (source: CheckoutV1.SourcesUpdateSourceRequestBody) =>
      justAPI.put<CheckoutV1.SourcesUpdateSourceResponseBody>('/v1/sources', source),
    mutationKey: [UPDATE_SOURCES_MUTATION_KEY],
    onMutate: async ({ card_id }) => {
      await queryClient.cancelQueries({ queryKey: [USER_INFO_QUERY_KEY] });
      const previousUser = queryClient.getQueryData<ReshapedUser>([USER_INFO_QUERY_KEY]);
      queryClient.setQueryData<ReshapedUser>([USER_INFO_QUERY_KEY], (oldUser): ReshapedUser | undefined => {
        const copySources = {
          ...oldUser?.sources,
        };
        copySources?.cards?.forEach((card) => {
          card.is_default = card.id === card_id;
        });
        copySources.selected = copySources.cards?.find((card) => card.id === card_id);
        return {
          ...(oldUser as ReshapedUser),
          sources: {
            cards: copySources.cards ?? [],
            selected: copySources.selected,
          },
        };
      });
      return {
        previousUser,
      };
    },
    onError: (err, source, context) => {
      queryClient.setQueryData<ReshapedUser>(['USER_INFO_QUERY_KEY'], context?.previousUser);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [USER_INFO_QUERY_KEY] });
    },
  });
};
