import { clearStorage, getStorage, setStorage } from '@getjust/commons';
import { createContext, useContext, useEffect, useState } from 'react';

import { useLoadingAtom } from '$hooks/state/useLoadingAtom';
import { USE_AUTH_LEGACY } from '$src/constants';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { setAccessToken, setRefreshToken } from '$src/http';
import { LegacyData } from '$src/types';

export enum AuthStatuses {
  PENDING = 'pending',
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
}

interface Context {
  authState: AuthStatuses;
  trySign: () => Promise<void>;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setLegacyState: (data: LegacyData) => void;
}

function getCookie(name: string) {
  const escape = (s: string) => s.replace(/([.*+?^$(){}|[\]/\\])/g, '\\$1');
  const match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
  return match ? match[1] : null;
}

export const authContext = createContext<Context | null>(null);

export const useAuth = () => useContext(authContext) as Context;

export const useProvideAuth = () => {
  const [authState, setAuthState] = useState<AuthStatuses>(AuthStatuses.PENDING);
  const { track } = useTrackAmplitude();
  const setIsAuthenticated = (isAuthenticated: boolean) =>
    setAuthState(isAuthenticated ? AuthStatuses.AUTHENTICATED : AuthStatuses.UNAUTHENTICATED);

  const trySign = async () => {
    try {
      if (USE_AUTH_LEGACY) {
        const token = getStorage<LegacyData>('localStorage', 'token');
        if (!token?.accessToken) throw new Error('No accessToken found.');
        setLegacyState(token);
      } else {
        const infoCookie = getCookie('JUSTC_ITOKEN');
        if (!infoCookie) throw new Error('No cookie found.');
      }
      track('Customer logged in');
      setIsAuthenticated(true);
    } catch (_) {
      setIsAuthenticated(false);
    }
  };

  const setLegacyState = (data: { accessToken: string; refreshToken: string }) => {
    setAccessToken(data.accessToken);
    setRefreshToken(data.refreshToken);
    if (!data.accessToken) {
      clearStorage('localStorage', 'token');
    } else {
      setStorage<LegacyData>('localStorage', 'token', data);
    }
  };

  return {
    authState,
    trySign,
    setIsAuthenticated,
    setLegacyState,
  };
};

export const AuthProvider = ({ children }: { children: any }) => {
  const auth = useProvideAuth();
  const [ready, setReady] = useState(false);
  const { onLoadingChange } = useLoadingAtom();

  useEffect(() => {
    const tryFn = async () => {
      await auth.trySign();
      setReady(true);
    };
    tryFn();
  }, []);

  useEffect(() => {
    onLoadingChange(!ready);
  }, [ready]);

  return (
    ready && (
      <>
        <authContext.Provider value={auth}>
          <>{children}</>
        </authContext.Provider>
      </>
    )
  );
};
