import { useMediaQuery } from '@chakra-ui/react';

export const useIsDesktop = () => {
  const [isLargerThan750, isLargerThan900, isTallerThan450] = useMediaQuery([
    '(min-width: 750px)',
    '(min-width: 900px)',
    '(min-height: 450px)',
  ]);
  return {
    isMobile: !isLargerThan750 || !isTallerThan450,
    isLargerThan750: isLargerThan750 && isTallerThan450,
    isLargerThan900: isLargerThan900 && isTallerThan450,
  };
};
