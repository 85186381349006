async function t(r) {
  if (r.order.outcome === "ready")
    try {
      const { origin: e } = new URL(r.sourceUrl ?? "");
      location.href = `${e}/pages/just-post-purchase?data=${btoa(JSON.stringify({ order: r.order }))}`;
    } catch {
      window.location.replace(r.order.url);
    }
}
function o() {
  return {
    redirectToOrderPage: t
  };
}
function c(r) {
  if (r === "shopify")
    return o();
}
export {
  c as getCMSInterceptors
};
