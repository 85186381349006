const ShippingLogo = ({ isCircle }: { isCircle?: boolean }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      {isCircle && <circle opacity="0.1" cx="18" cy="18" r="18" fill="#C9C9C9" />}
      <path
        transform="translate(6, 6)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33199 6.44402V8.85072H7.95147C8.35022 8.85072 8.67347 9.17397 8.67347 9.57272C8.67347 9.97148 8.35022 10.2947 7.95147 10.2947H5.33199V11.3419H7.53583C7.93458 11.3419 8.25783 11.6652 8.25783 12.064C8.25783 12.4627 7.93458 12.786 7.53583 12.786H5.33199V15.4577H7.41467C7.72866 14.2025 8.86401 13.2728 10.2165 13.2728C11.569 13.2728 12.7044 14.2025 13.0183 15.4577H13.2741H15.7421V12.027V8.70481V6.44402H5.33199ZM5.02526 16.9017H7.42439C7.75151 18.1378 8.87761 19.0489 10.2165 19.0489C11.5554 19.0489 12.6815 18.1378 13.0086 16.9017H13.2741H16.4641H16.9754C17.3025 18.1378 18.4286 19.0489 19.7675 19.0489C21.2089 19.0489 22.4036 17.993 22.6204 16.6125C22.8533 16.4043 22.9999 16.1015 22.9999 15.7644V12.027C22.9999 11.8521 22.9365 11.6832 22.8213 11.5515L20.0385 8.37118C19.8225 8.12437 19.5105 7.9828 19.1826 7.9828H17.1861V6.13728C17.1861 5.50918 16.6769 5 16.0488 5H5.02526C4.39716 5 3.88798 5.50918 3.88798 6.13728V8.85072H3.38347C2.98471 8.85072 2.66146 9.17397 2.66146 9.57272C2.66146 9.97148 2.98471 10.2947 3.38347 10.2947H3.88798V11.3419H1.72201C1.32325 11.3419 1 11.6652 1 12.064C1 12.4627 1.32325 12.786 1.72201 12.786H3.88798V15.7644C3.88798 16.3925 4.39715 16.9017 5.02526 16.9017ZM21.5559 13.893V12.749H17.1861V14.8644C17.6611 13.9204 18.6387 13.2728 19.7675 13.2728C20.4429 13.2728 21.0641 13.5046 21.5559 13.893ZM17.1861 11.305H20.6868L19.0434 9.42682H17.1861V11.305ZM10.2165 17.6049C11.014 17.6049 11.6605 16.9584 11.6605 16.1608C11.6605 15.3633 11.014 14.7168 10.2165 14.7168C9.419 14.7168 8.77249 15.3633 8.77249 16.1608C8.77249 16.9584 9.419 17.6049 10.2165 17.6049ZM21.2115 16.1608C21.2115 16.9584 20.565 17.6049 19.7675 17.6049C18.97 17.6049 18.3235 16.9584 18.3235 16.1608C18.3235 15.3633 18.97 14.7168 19.7675 14.7168C20.565 14.7168 21.2115 15.3633 21.2115 16.1608Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default ShippingLogo;
