import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { QueryParamsAtom, queryParamsAtom } from '$business/atoms/query-params';
import { ZFormQueryParams } from '$models/http';

export function useQueryParamsAtom() {
  const [queryParams, setQueryParams] = useAtom(queryParamsAtom);

  const onQueryParamsChange = useCallback(
    (params: Partial<QueryParamsAtom>) => {
      const safe = ZFormQueryParams.safeParse({
        ...(queryParams ?? {}),
        ...params,
      });

      if (safe.success) {
        setQueryParams(safe.data);
      } else {
        console.error('Error while parsing query params change', {
          error: safe.error,
          hook: useQueryParamsAtom.name,
        });
      }
    },
    [queryParams, setQueryParams],
  );

  return { queryParams, onQueryParamsChange };
}

export function useROQueryParamsAtom() {
  const queryParams = useAtomValue(queryParamsAtom);

  return queryParams;
}
