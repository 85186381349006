import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import type { CheckoutV1 } from '@getjust/api-schema';

import { USER_INFO_QUERY_KEY } from '$src/constants';
import { useErrorAtom } from '$src/hooks/state/useErrorAtom';
import { justAPI } from '$src/http';

const ADD_SOURCE_MUTATION_KEY = 'MUTATION/ADD_SOURCE';

export const useAddSource = () => {
  const { onErrorChange } = useErrorAtom();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CheckoutV1.SourcesCreateSourceRequestBody) =>
      justAPI.post<CheckoutV1.SourcesCreateSourceResponseBody>('/v1/sources', data),
    mutationKey: [ADD_SOURCE_MUTATION_KEY],
    onError: (error: any) => {
      const errorCode = error.response.data?.decline_code ?? error.response.data?.code ?? 'card_declined';
      const titleError = `errors.stripe.${errorCode}.title`;
      const sentenceError = `errors.stripe.${errorCode}.sentence`;
      const genericTitleError = `errors.stripe.generic_decline.title`;
      const genericSentenceError = `errors.stripe.generic_decline.sentence`;
      onErrorChange({
        title: i18n.exists(titleError) ? t(titleError) : t(genericTitleError),
        sentence: i18n.exists(sentenceError) ? t(sentenceError) : t(genericSentenceError),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USER_INFO_QUERY_KEY] });
    },
  });
};
