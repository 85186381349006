import { useMutation } from '@tanstack/react-query';

import type { RecommendedCodesResult } from '@getjust/gateway';

import { useFormMessageAtom } from '$hooks/state';
import { MUTATION_ESTIMATED_SHIPPING } from '$src/constants';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useRecommendedDiscountsAtom } from '$src/hooks/state/useRecommendedDiscountsAtom';
import { gateway } from '$src/http';
import { RecommendedDiscountsInput } from '$src/pages/api/[shopId]/recommend-discounts';

export const MUTATION_ESTIMATED_SHIPPING_KEY = 'MUTATION/MUTATION_ESTIMATED_SHIPPING';

export const useRecommendedDiscounts = () => {
  const { formMessage } = useFormMessageAtom();
  const { track } = useTrackAmplitude();

  const { onRecommendedDiscount } = useRecommendedDiscountsAtom();
  return useMutation({
    mutationFn: (estimatedShippingInput: RecommendedDiscountsInput) =>
      gateway.post<RecommendedCodesResult>(
        `/api/${formMessage?.shopId}/recommend-discounts`,
        estimatedShippingInput,
      ),
    gcTime: Infinity,
    mutationKey: [MUTATION_ESTIMATED_SHIPPING],
    onSuccess: ({ data }) => {
      if (data.outcome === 'found') {
        onRecommendedDiscount(data.codes);
      }
    },
    onError: (err, payload) => {
      track('Estimated shipping checkout form load failed', {
        payload: JSON.stringify(payload),
      });
    },
  });
};
