function w(e, n) {
  return 0 .toLocaleString(e, {
    style: "currency",
    currency: n,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).replace(/\d/g, "").trim();
}
function p(e) {
  return typeof e == "string" ? parseFloat(e) === 0 : !1;
}
function S(e, n) {
  const { currency: t = "EUR" } = n, a = n.locale ?? (t === "EUR" ? "fr-FR" : "en-US");
  return new Intl.NumberFormat(a, {
    currency: t,
    style: "currency",
    maximumFractionDigits: 2
  }).format(e);
}
function x(e) {
  return e.toString().replace(".", ",");
}
const T = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  formatDecimal: x,
  formatPrice: S,
  getCurrencySymbol: w,
  isFree: p
}, Symbol.toStringTag, { value: "Module" }));
function m(e) {
  try {
    const n = window[e], t = "__storage_test__";
    return n.setItem(t, t), n.removeItem(t), !0;
  } catch {
    return !1;
  }
}
function A(e, n, t) {
  m(e) && window[e].setItem(n, JSON.stringify(t));
}
function O(e, n) {
  if (!m(e)) return null;
  const t = window[e].getItem(n);
  return t ? JSON.parse(t) : null;
}
function v(e, n, t, a) {
  if (!m(e)) return;
  const r = Date.now();
  window[e].setItem(n, JSON.stringify({ data: JSON.stringify(t), expiry: r + a }));
}
function b(e, n) {
  if (!m(e)) return null;
  const t = window[e].getItem(n);
  if (t) {
    const a = JSON.parse(t);
    return Date.now() > a.expiry ? (D("localStorage", n), null) : JSON.parse(a.data);
  }
  return null;
}
function D(e, n) {
  m(e) && window[e].removeItem(n);
}
const I = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  clearStorage: D,
  getStorage: O,
  getStorageWithTtl: b,
  setStorage: A,
  setStorageWithTtl: v,
  storageAvailable: m
}, Symbol.toStringTag, { value: "Module" })), M = (e, n) => {
  let t = null;
  return (...a) => {
    window.clearTimeout(t), t = window.setTimeout(() => {
      e.apply(null, a);
    }, n);
  };
}, _ = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  debounce: M
}, Symbol.toStringTag, { value: "Module" })), s = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
function N(e, n) {
  let t = (e + 1) % s.length;
  for (; !n.includes(s[t] ?? ""); )
    t = (t + 1) % s.length;
  return t;
}
function g(e, n, t, a) {
  const r = e.getDay(), o = s[r], i = new Date(e);
  if (i.setDate(i.getDate() + n), e.getHours() >= t || !a.includes(o ?? "")) {
    const u = (N(r, a) - r + 7) % 7 || 1;
    return i.setDate(i.getDate() + u), i.setHours(0), i;
  }
  return i;
}
function h(e, n, t) {
  let a = 0;
  const r = new Date(e);
  for (; a < n; )
    r.setDate(r.getDate() + 1), t.includes(s[r.getDay()] ?? "") && a++;
  return r;
}
function P(e, n) {
  const t = /* @__PURE__ */ new Date(), a = e.warehouse.custom[n] ?? e.warehouse.default;
  let r = t, o = t.getDay();
  for (; !a[s[o]]; )
    r.setDate(r.getDate() + 1), o = r.getDay();
  const { preparationTime: i, cutOffTime: l } = a[s[o]];
  r = g(r, i, l, [
    s[o]
  ]);
  let u = /* @__PURE__ */ new Date("9999-12-31");
  return e.carrier.forEach((c) => {
    if (c.active) {
      const d = h(r, c.shippingTime, c.deliveryDays);
      d < u && (u = d);
    }
  }), u;
}
function R({
  carrierId: e,
  settings: n,
  categoryId: t
}) {
  const a = /* @__PURE__ */ new Date(), r = t ? n.warehouse.custom[t] ?? n.warehouse.default : n.warehouse.default;
  let o = a, i = a.getDay();
  for (; !r[s[i]]; )
    o.setDate(o.getDate() + 1), i = o.getDay();
  const { preparationTime: l, cutOffTime: u } = r[s[i]];
  o = g(o, l, u, [
    s[i]
  ]);
  const c = n.carrier.find((d) => d.id === e) || n.carrier.find((d) => e.startsWith(d.id));
  return c != null && c.active ? h(o, c.shippingTime, c.deliveryDays) : null;
}
function y(e, n) {
  let t = 0;
  for (; t < 7; ) {
    const a = (n + 1 + t) % 7, r = s[a];
    if (e[r])
      return {
        nextAvailableDay: r,
        cutOffTime: e[r].cutOffTime
      };
    t++;
  }
  return null;
}
function C(e, n) {
  const t = /* @__PURE__ */ new Date(), a = e.warehouse.custom[n] ?? e.warehouse.default, r = t.getDay();
  let o = a[s[r]] && t.getHours() < a[s[r]].cutOffTime ? {
    nextAvailableDay: s[r],
    cutOffTime: a[s[r]].cutOffTime
  } : y(a, r);
  if (!o)
    return -1;
  let i = s.indexOf(o.nextAvailableDay), l = (i - r + 7) % 7;
  if (l === 0 && t.getHours() >= a[s[r]].cutOffTime) {
    if (o = y(a, r), !o)
      return -1;
    i = s.indexOf(o.nextAvailableDay), l = (i - r + 7) % 7 || 1;
  }
  const u = new Date(t);
  return u.setDate(u.getDate() + l), u.setHours(o.cutOffTime, 0, 0, 0), Math.floor((u.getTime() - t.getTime()) / 1e3);
}
const f = {
  fr: {
    hours: "heures",
    minutes: "minutes",
    seconds: "secondes"
  },
  en: {
    hours: "hours",
    minutes: "minutes",
    seconds: "seconds"
  },
  es: {
    hours: "horas",
    minutes: "minutos",
    seconds: "segundos"
  },
  it: {
    hours: "ore",
    minutes: "minuti",
    seconds: "secondi"
  },
  de: {
    hours: "Stunden",
    minutes: "Minuten",
    seconds: "Sekunden"
  },
  nl: {
    hours: "uren",
    minutes: "minuten",
    seconds: "seconden"
  },
  sv: {
    hours: "timmar",
    minutes: "minuter",
    seconds: "sekunder"
  }
};
function E(e, n) {
  const t = String(e.getDate()).padStart(2, "0"), a = String(e.getMonth() + 1).padStart(2, "0"), r = e.getFullYear();
  return n.replaceAll("YYYY", r.toString()).replaceAll("DD", t).replaceAll("MM", a);
}
function F(e, n = "en") {
  var o, i, l, u, c, d;
  const t = Math.floor(e / 3600), a = Math.floor(e % 3600 / 60), r = e % 60;
  return `${t.toString()} ${((o = f[n]) == null ? void 0 : o.hours) ?? ((i = f.en) == null ? void 0 : i.hours)} ${a.toString().padStart(2, "0")} ${((l = f[n]) == null ? void 0 : l.minutes) ?? ((u = f.en) == null ? void 0 : u.minutes)} ${r.toString().padStart(2, "0")} ${((c = f[n]) == null ? void 0 : c.seconds) ?? ((d = f.en) == null ? void 0 : d.seconds)}`;
}
function Y() {
  return window.ApplePaySession ? window.ApplePaySession.canMakePaymentsWithActiveCard("test").then((e) => e).catch((e) => (console.error("Error checking for active card:", e), !1)) : !1;
}
function j() {
  if (!window.PaymentRequest)
    return Promise.resolve(!1);
  const e = [
    {
      supportedMethods: "https://google.com/pay",
      data: {
        environment: "TEST",
        // Change to PRODUCTION as necessary
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
              allowedCardNetworks: ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"]
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "example",
                gatewayMerchantId: "exampleGatewayMerchantId"
              }
            }
          }
        ],
        merchantInfo: {
          merchantId: "01234567890123456789",
          merchantName: "JUST"
        }
      }
    }
  ], n = {
    total: {
      label: "Total",
      amount: { currency: "EUR", value: "0.00" }
    }
  };
  return new PaymentRequest(e, n).canMakePayment().then(function(a) {
    return a;
  }).catch(function(a) {
    return console.error("Error with PaymentRequest:", a), !1;
  });
}
const J = {
  ...I,
  ..._,
  ...T
};
export {
  R as calculateDeliveryLeadtimeForCarrierId,
  P as calculateMinimumDeliveryLeadtime,
  C as calculateNextAvailableOrderTimeInSeconds,
  D as clearStorage,
  M as debounce,
  J as default,
  E as formatDate,
  x as formatDecimal,
  S as formatPrice,
  F as formatSecondsToTime,
  w as getCurrencySymbol,
  O as getStorage,
  b as getStorageWithTtl,
  Y as isApplePayAvailable,
  p as isFree,
  j as isGooglePaySupported,
  A as setStorage,
  v as setStorageWithTtl,
  m as storageAvailable
};
