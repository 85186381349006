import type { QueryError, ReshapedUser } from '$src/types';
import type { Cart, PublicShop } from '@getjust/gateway';

import { LocationResponse, useCart, useShop, useUserInfo, useUserLocationDetection } from '$hooks/queries';

interface InitialQuery<T, E = Error> {
  data?: T;
  error?: QueryError<E> | null;
  status: 'pending' | 'error' | 'success' | 'idle';
  name?: string;
}

type InitialAuthenticatedQueryReturnType = [
  InitialQuery<PublicShop, unknown>,
  InitialQuery<ReshapedUser, unknown>,
  InitialQuery<Cart, unknown>,
  InitialQuery<LocationResponse, unknown>,
];

export function useInitialAuthenticatedQueries(): InitialAuthenticatedQueryReturnType {
  const { data: shopData, status: sellerStatus } = useShop();
  const { data: userInfo, status: userInfoStatus } = useUserInfo();
  const { data: cart, status: cartStatus } = useCart();
  const { data: location, status: locationStatus } = useUserLocationDetection();

  const responses = [
    {
      data: shopData,
      status: sellerStatus,
    },
    {
      data: userInfo,
      status: userInfoStatus,
    },
    {
      data: cart,
      status: cartStatus,
    },
    {
      data: location,
      status: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? locationStatus : 'idle',
    },
  ] as InitialAuthenticatedQueryReturnType;

  return responses;
}
