import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'next-i18next';

import StarLoadingAnimation from '$animations/starLoading.json';
import SmallJust from '$components/SvgLogos/SmallJust';
import { useShop } from '$src/hooks/queries';

export const FormLoader = () => {
  const { t, i18n } = useTranslation();
  const { data: shop } = useShop();

  return (
    <Box
      position="relative"
      as="main"
      flex="1 1 auto"
      overflow="auto"
      p="0"
      m="0"
      height="100%"
      display="flex"
      backgroundColor="white"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      alignSelf="center"
      zIndex="10000"
    >
      <Stack
        w="full"
        display="flex"
        position="relative"
        alignItems="center"
        alignContent="center"
        alignSelf="center"
      >
        <Player src={StarLoadingAnimation} autoplay loop speed={1} style={{ height: '12.5rem' }}>
          <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>

        <Box w="full" position="fixed" justifyContent="center" alignItems="center" mt="12rem">
          <HStack
            as="div"
            animation="fadeInOut 7800ms infinite"
            justifyContent="center"
            alignItems="center"
            spacing="1"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
              <path
                fill="#1C1C1C"
                d="M11.194 15c.166 0 .306-.072.472-.203l4.173-3.35c.28-.226.411-.512.411-.822 0-.31-.131-.596-.411-.823l-4.173-3.35c-.166-.13-.306-.202-.472-.202-.315 0-.587.334-.587.942v6.866c0 .608.272.942.587.942Zm-5.617 0c.167 0 .315-.072.473-.203l4.173-3.35c.288-.226.41-.512.41-.822 0-.31-.122-.596-.41-.823L6.05 6.452c-.158-.13-.306-.202-.473-.202-.315 0-.577.334-.577.942v6.866c0 .608.262.942.577.942Z"
              />
            </svg>
            <Text textAlign="center">{t('loader.checkoutFaster')}</Text>
          </HStack>
          <HStack
            as="div"
            animation="fadeInOut2 7800ms infinite"
            justifyContent="center"
            alignItems="center"
            spacing="1"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none">
              <path
                fill="#1C1C1C"
                d="M5.439 16.25h9.323c.743 0 1.188-.348 1.188-.925 0-1.793-2.245-4.268-5.853-4.268-3.601 0-5.847 2.475-5.847 4.268 0 .577.445.925 1.189.925Zm4.665-6.438c1.488 0 2.78-1.335 2.78-3.073 0-1.717-1.293-2.989-2.78-2.989-1.488 0-2.781 1.3-2.781 3.003 0 1.724 1.286 3.06 2.78 3.06Z"
              />
            </svg>
            <Text textAlign="center">{t('loader.allInOne')}</Text>
          </HStack>
          <HStack
            as="div"
            animation="fadeInOut3 7800ms infinite"
            justifyContent="center"
            alignItems="center"
            spacing="1"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
              <path
                fill="#050505"
                d="M6.648 16.25h6.704c1.077 0 1.648-.56 1.648-1.668V9.753c0-1.107-.571-1.66-1.648-1.66H6.648C5.571 8.092 5 8.646 5 9.752v4.829c0 1.107.571 1.668 1.648 1.668Zm-.366-7.618h1.165V6.263c0-1.766 1.18-2.705 2.55-2.705 1.37 0 2.563.94 2.563 2.705v2.37h1.158V6.417c0-2.636-1.802-3.918-3.722-3.918-1.912 0-3.714 1.282-3.714 3.918v2.214Z"
              />
            </svg>
            <Text textAlign="center">{t('loader.safe')}</Text>
          </HStack>
        </Box>
      </Stack>

      {shop?.name !== undefined && (
        <HStack spacing={1} pb={3.5} position="absolute" bottom="0">
          <a
            href={
              i18n.language === 'fr'
                ? 'https://www.getjust.eu/fr/consommateurs'
                : 'https://www.getjust.eu/shoppers'
            }
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            rel="noreferrer"
          >
            <SmallJust />
          </a>
          <Text color="#8F8F8F" textAlign="center" fontSize="xs" fontWeight="350" lineHeight="md">
            {t('loader.authorizedBy') + ' ' + shop.name}
          </Text>
        </HStack>
      )}
      <Stack spacing={4}></Stack>
    </Box>
  );
};
