import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useCartIdUpdater } from '$src/hooks/client/useCartIdUpdater';
import { gateway } from '$src/http';
import { AddLineItemsInput } from '$src/pages/api/[shopId]/[cartId]/add-line-items';

export const MUTATION_CART_ADD_LINE_ITEMS_KEY = 'MUTATION/CART_ADD_LINE_ITEMS';

export const useAddLineItems = () => {
  const queryClient = useQueryClient();
  const baseUrl = useBaseApiUrl();
  const updateCartId = useCartIdUpdater();

  return useMutation({
    mutationFn: (items: AddLineItemsInput) => gateway.post<Cart>(`${baseUrl}/add-line-items`, items),
    mutationKey: [MUTATION_CART_ADD_LINE_ITEMS_KEY, baseUrl],
    onSuccess: ({ data }) => {
      updateCartId(data.id);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
    },
  });
};
