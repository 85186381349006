import posthog from 'posthog-js';

export const capturePosthogAnalytics = (
  name: string,
  props?: {
    user?: string;
    registered?: boolean;
    seller?: string;
    [key: string]: any;
  },
) => {
  try {
    if (!process.env.NEXT_PUBLIC_ENABLE_ANALYTICS) {
      return;
    }

    return posthog.capture(name, props);
  } catch (error) {
    console.error('Failed to capture posthog', { error });
    return;
  }
};
