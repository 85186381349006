import { atom } from 'jotai';
import z from 'zod';

export const ZRecommendedDiscount = z.array(
  z.object({
    code: z.string(),
    totalDiscount: z.number(),
  }),
);

export type RecommendedDiscount = z.infer<typeof ZRecommendedDiscount>;

export const recommendedDiscount = atom<z.infer<typeof ZRecommendedDiscount> | undefined>(undefined);
