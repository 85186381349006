import { useJustDrawer } from '@getjust/leaf';
import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { showChallengeAtom } from '$business/atoms/show-challenge';

export function useShowChallengeAtom(): {
  showChallenge: 'hide' | 'validate' | 'login';
  close: () => void;
  showLoginChallenge: () => void;
  showValidateChallenge: () => void;
} {
  const [showChallenge, setShowChallenge] = useAtom(showChallengeAtom);
  const { onClose, onOpen } = useJustDrawer();

  const showLoginChallenge = useCallback(() => {
    setShowChallenge('login');
    onOpen();
  }, [setShowChallenge, onOpen]);

  const showValidateChallenge = useCallback(() => {
    setShowChallenge('validate');
    onOpen();
  }, [setShowChallenge, onOpen]);

  const close = useCallback(() => {
    setShowChallenge('hide');
    onClose();
  }, [setShowChallenge, onClose]);

  return { showLoginChallenge, showValidateChallenge, close, showChallenge };
}
