import { useAtom } from 'jotai';

import {
  RecommendedDiscount,
  ZRecommendedDiscount,
  recommendedDiscount,
} from '$src/business/atoms/recommended-discount';

export function useRecommendedDiscountsAtom() {
  const [recommendedDiscountsAtom, setRecommendedDIscountsAtom] = useAtom(recommendedDiscount);

  const onRecommendedDiscount = (nextValue?: RecommendedDiscount) => {
    const parse = ZRecommendedDiscount.safeParse(nextValue);
    if (parse.success) {
      setRecommendedDIscountsAtom(parse.data);
    } else {
      console.error('Error while getting recommended codes', {
        error: parse.error,
        hook: useRecommendedDiscountsAtom.name,
      });
    }
  };

  return {
    recommendedDiscountsAtom,
    onRecommendedDiscount,
  };
}
