import { atom } from 'jotai';
import z from 'zod';

import { ZFormQueryParams } from '$models/http';

export type QueryParamsAtom = z.infer<typeof ZFormQueryParams>;

export const queryParamsAtom = atom<QueryParamsAtom>({
  sellerId: '',
});
