import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CheckoutV1, CheckoutV2 } from '@getjust/api-schema';

import { USER_INFO_QUERY_KEY } from '$src/constants';
import { justAPI } from '$src/http';

const ADD_ADDRESS_MUTATION_KEY = 'MUTATION/ADD_ADDRESS';

export const useAddAddress = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (address: CheckoutV2.CreateProfileAddressRequestBody) =>
      justAPI.post<CheckoutV2.CreateProfileAddressResponseBody>('/v2/profile/address', address),
    mutationKey: [ADD_ADDRESS_MUTATION_KEY],
    onMutate: async (address): Promise<{ previousUser: CheckoutV1.AuthMeResponseBody | undefined }> => {
      await queryClient.cancelQueries({ queryKey: [USER_INFO_QUERY_KEY] });
      const previousUser = queryClient.getQueryData<CheckoutV1.AuthMeResponseBody>([USER_INFO_QUERY_KEY]);
      queryClient.setQueryData<CheckoutV1.AuthMeResponseBody>(
        [USER_INFO_QUERY_KEY],
        (oldUser): CheckoutV1.AuthMeResponseBody | undefined => {
          const copyAddresses = [...(oldUser?.addresses ?? [])];
          copyAddresses.forEach((address) => {
            address.is_default_shipping = false;
            address.is_default_billing = false;
          });
          copyAddresses.push({ ...address, id: 'optimisticUpdateAdd' });
          return {
            ...(oldUser as CheckoutV1.AuthMeResponseBody),
            addresses: copyAddresses,
          };
        },
      );
      return {
        previousUser,
      };
    },
    onError: (err, newAddress, context) => {
      queryClient.setQueryData<CheckoutV1.AuthMeResponseBody>(['USER_INFO_QUERY_KEY'], context?.previousUser);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [USER_INFO_QUERY_KEY] });
    },
  });
};
