import { useMutation } from '@tanstack/react-query';

import type { EstimatedShippingResult } from '@getjust/gateway';

import { useFormMessageAtom } from '$hooks/state';
import { MUTATION_ESTIMATED_SHIPPING } from '$src/constants';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useEstimatedShippingAtom } from '$src/hooks/state/useEstimatedShippingAtom';
import { gateway } from '$src/http';
import { EstimatedShippingInput } from '$src/pages/api/[shopId]/estimated-shipping';

export const MUTATION_ESTIMATED_SHIPPING_KEY = 'MUTATION/MUTATION_ESTIMATED_SHIPPING';

export const useEstimatedShipping = () => {
  const { formMessage } = useFormMessageAtom();
  const { track } = useTrackAmplitude();
  const { onEstimatedShippingChange } = useEstimatedShippingAtom();

  return useMutation({
    mutationFn: (estimatedShippingInput: EstimatedShippingInput) =>
      gateway.post<EstimatedShippingResult>(
        `/api/${formMessage?.shopId}/estimated-shipping`,
        estimatedShippingInput,
      ),
    gcTime: Infinity,
    mutationKey: [MUTATION_ESTIMATED_SHIPPING],
    onSuccess: ({ data }) => {
      if (data.outcome === 'found') {
        onEstimatedShippingChange(data.estimated);
      }
    },
    onError: (err, payload) => {
      track('Estimated shipping checkout form load failed', {
        payload: JSON.stringify(payload),
      });
    },
  });
};
