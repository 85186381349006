import { getSessionId } from '@amplitude/analytics-browser';
import { useQuery } from '@tanstack/react-query';

import type { ReshapedUser } from '$src/types';
import type { CheckoutV1 } from '@getjust/api-schema';

import { useCustomRouter } from '$hooks/client';
import { useShop } from '$hooks/queries';
import { useCountryCodeAtom, useEmailAtom } from '$hooks/state';
import { USER_INFO_QUERY_KEY } from '$src/constants';
import { AuthStatuses, useAuth } from '$src/hooks/auth';
import { justAPI } from '$src/http';
import { capturePosthogAnalytics } from '$src/utils';

export const useUserInfo = () => {
  const { authState } = useAuth() ?? {};
  const router = useCustomRouter();
  const { data: shop } = useShop();
  const { changeCountry } = useCountryCodeAtom();
  const { onEmailChange } = useEmailAtom();

  return useQuery({
    queryKey: [USER_INFO_QUERY_KEY],
    queryFn: async () => {
      try {
        const response = await justAPI.get<CheckoutV1.AuthMeResponseBody>('/v1/auth/me');
        const reshaped = reshapeCurrentUserData(response.data);
        changeCountry(reshaped?.address?.country);
        onEmailChange(reshaped?.email);
        capturePosthogAnalytics('customer logged', {
          pspCustomerId: response?.data?.customer_id,
          customerId: response?.data?.id,
          amplitudeSessionId: getSessionId(),
        });
        return reshaped;
      } catch (error: any) {
        if (error?.type === 'RequestError') {
          switch (error.status) {
            case 404:
              router.push('/unauthenticated');
              return;
            default:
              return;
          }
        }
        return;
      }
    },
    enabled: authState === AuthStatuses.AUTHENTICATED && Boolean(shop),
  });
};

export function reshapeCurrentUserData(data: CheckoutV1.AuthMeResponseBody): ReshapedUser {
  const address = data?.addresses?.find((address) => address.is_default_shipping);
  const billing_address = data?.addresses?.find((address) => address.is_default_billing) ?? address;
  const formattedSources = (data.sources ?? []).flatMap((source) => {
    return source.card
      ? {
          id: source.id,
          is_default: !!source.is_default,
          ...source.card,
          last4: source.card.last_4, // @TODO should be sent directly like this by backend
        }
      : [];
  });

  return {
    email: data.email,
    pspCustomerId: data.customer_id,
    id: data.id,
    user: {
      lastname: data?.personal_informations?.last_name,
      firstname: data?.personal_informations?.first_name,
      phone_number: data?.phone,
    },
    address: address
      ? {
          ...address?.address,
          id: address?.id,
        }
      : undefined,

    billing_address: billing_address
      ? {
          ...billing_address?.address,
          id: billing_address?.id,
        }
      : undefined,
    sources: {
      cards: formattedSources,
      selected: formattedSources?.find((source) => source.is_default),
    },
    addresses:
      data?.addresses?.map(({ address, ...rest }) => ({
        ...address,
        ...rest,
      })) ?? [],
  };
}
