import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useCartIdUpdater } from '$src/hooks/client/useCartIdUpdater';
import { useCart } from '$src/hooks/queries';
import { gateway } from '$src/http';
import { DeleteLineItemsInput } from '$src/pages/api/[shopId]/[cartId]/delete-line-items';

const MUTATION_CART_DELETE_LINE_ITEMS_KEY = 'MUTATION/REMOVE_CHECKOUT_LINE_ITEMS';

export const useDeleteLineItems = () => {
  const baseUrl = useBaseApiUrl();
  const queryClient = useQueryClient();
  const updateCartId = useCartIdUpdater();
  const { data: cart } = useCart();

  return useMutation({
    mutationFn: (items: DeleteLineItemsInput) => gateway.post<Cart>(`${baseUrl}/delete-line-items`, items),
    mutationKey: [MUTATION_CART_DELETE_LINE_ITEMS_KEY],
    onSuccess: ({ data }) => {
      updateCartId(data.id);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
    },
    onMutate: async ({ lineItemIds }) => {
      const previousCart = queryClient.getQueryData<Cart>([CART_QUERY_KEY, cart?.id]);
      const newLineItems =
        previousCart?.lineItems?.filter((lineItem) => lineItemIds.some((id) => id !== lineItem.id)) ?? [];
      const newCart = { ...previousCart, lineItems: newLineItems };
      // @ts-expect-error - known
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, previousCart?.id], newCart);
      // Return a context object with the snapshotted value
      return { previousCart };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, data, context) => {
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, context?.previousCart?.id], context?.previousCart);
    },
  });
};
