import { useQuery } from '@tanstack/react-query';

import type { PublicShop } from '@getjust/gateway';

import { useROFormMessageAtom } from '$hooks/state';
import { gateway } from '$src/http';

export type withConfig = {
  userInputMandatory?: boolean;
};

export type WithSettings = {
  settings: {
    pickup: {
      activated: true;
      provider: {
        type: 'bigblue';
        apiToken: string;
      };
    };
    gdprMarketing?: {
      emailConsent: boolean;
      smsConsent: boolean;
    };
    gdprAccount?: {
      createAccount: boolean;
    };
  };
};

const SHOP_QUERY_KEY = 'QUERY/SHOP';

export function useShop() {
  const message = useROFormMessageAtom();

  return useQuery({
    queryKey: [SHOP_QUERY_KEY],
    queryFn: async () => gateway.get<PublicShop & WithSettings & withConfig>(`/api/${message?.shopId}`),
    select: (response) => response.data,
    enabled: Boolean(message?.shopId),
  });
}
