import { useAtom } from 'jotai';

import {
  EstimatedShippingAtom,
  ZEstimatedShipping,
  estimatedShippingAtom,
} from '$src/business/atoms/estimated-shipping';

export function useEstimatedShippingAtom() {
  const [estimatedShipping, setEstimatedShipping] = useAtom(estimatedShippingAtom);

  const onEstimatedShippingChange = (nextValue?: EstimatedShippingAtom) => {
    const parse = ZEstimatedShipping.safeParse(nextValue);
    if (parse.success) {
      setEstimatedShipping(parse.data);
    } else {
      console.error('Error while estimating shipping change', {
        error: parse.error,
        hook: useEstimatedShippingAtom.name,
      });
    }
  };

  return {
    estimatedShipping,
    onEstimatedShippingChange,
  };
}
