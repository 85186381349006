import { useTranslation } from 'next-i18next';

import { HttpStatusCode, QueryError } from '$src/types';

export type Translations<TError> = {
  serverError?: string;
  networkError?: string;
  requestError?: (reason: TError, httpStatusCode: HttpStatusCode) => string | undefined;
};

export const useQueryError = <TError>(translations: Translations<TError>) => {
  const { t } = useTranslation('common');
  return (queryError: QueryError<TError>): string => t(getErrorTitle({ queryError, translations }));
};

const getErrorTitle = <TError>({
  queryError,
  translations,
}: {
  queryError: QueryError<TError>;
  translations: Translations<TError>;
}): string => {
  switch (queryError.type) {
    case 'NetworkError':
      return translations.networkError ?? 'errors.networkError';
    case 'RequestError':
      return (
        translations.requestError?.(queryError.data, queryError.status) ??
        getErrorTitleFromHttpStatus(queryError.status) ??
        'errors.unknownError'
      );
    case 'ServerError':
      return translations.serverError ?? 'errors.serverError';
    default:
      return 'errors.unknownError';
  }
};

const getErrorTitleFromHttpStatus = (httpStatusCode: HttpStatusCode): string | undefined => {
  switch (httpStatusCode) {
    case 400:
      return 'errors.requestErrors.badRequest';
    case 401:
      return 'errors.requestErrors.unauthorized';
    case 403:
      return 'errors.requestErrors.forbidden';
    case 404:
      return 'errors.requestErrors.notFound';
    case 408:
      return 'errors.requestErrors.timeout';
    case 413:
      return 'errors.requestErrors.payloadTooLarge';
    case 429:
      return 'errors.requestErrors.tooManyRequests';
    default:
      return undefined;
  }
};
