import { Oops } from '@getjust/oops';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { useShop } from '$hooks/queries/useShop';
import { USER_LOCATION_DETECTION_QUERY_KEY } from '$src/constants';
import { useFormMessageAtom } from '$src/hooks/state';
import { capturePosthogAnalytics } from '$src/utils';
import { withTimeout } from '$src/utils/timeout';

export type LocationResponse = {
  location: {
    latitude: number;
    longitude: number;
    country: {
      code: string;
    };
  };
};

const countriesCoordinates: Record<string, { city: string; latitude: number; longitude: number }> = {
  DE: { city: 'Berlin', latitude: 52.52, longitude: 13.405 },
  AT: { city: 'Vienna', latitude: 48.2082, longitude: 16.3738 },
  BE: { city: 'Brussels', latitude: 50.8503, longitude: 4.3517 },
  BG: { city: 'Sofia', latitude: 42.6977, longitude: 23.3219 },
  CY: { city: 'Nicosia', latitude: 35.1856, longitude: 33.3823 },
  HR: { city: 'Zagreb', latitude: 45.815, longitude: 15.9819 },
  DK: { city: 'Copenhagen', latitude: 55.6761, longitude: 12.5683 },
  ES: { city: 'Madrid', latitude: 40.4168, longitude: -3.7038 },
  EE: { city: 'Tallinn', latitude: 59.437, longitude: 24.7535 },
  FI: { city: 'Helsinki', latitude: 60.1695, longitude: 24.9354 },
  FR: { city: 'Paris', latitude: 48.8566, longitude: 2.3522 },
  GR: { city: 'Athens', latitude: 37.9838, longitude: 23.7275 },
  HU: { city: 'Budapest', latitude: 47.4979, longitude: 19.0402 },
  IE: { city: 'Dublin', latitude: 53.3498, longitude: -6.2603 },
  IT: { city: 'Rome', latitude: 41.9028, longitude: 12.4964 },
  LV: { city: 'Riga', latitude: 56.9496, longitude: 24.1052 },
  LT: { city: 'Vilnius', latitude: 54.6872, longitude: 25.2797 },
  LU: { city: 'Luxembourg', latitude: 49.6117, longitude: 6.1319 },
  MT: { city: 'Valletta', latitude: 35.8997, longitude: 14.5147 },
  NL: { city: 'Amsterdam', latitude: 52.3676, longitude: 4.9041 },
  PL: { city: 'Warsaw', latitude: 52.2297, longitude: 21.0122 },
  PT: { city: 'Lisbon', latitude: 38.7223, longitude: -9.1393 },
  CZ: { city: 'Prague', latitude: 50.0755, longitude: 14.4378 },
  RO: { city: 'Bucharest', latitude: 44.4268, longitude: 26.1025 },
  SK: { city: 'Bratislava', latitude: 48.1486, longitude: 17.1077 },
  SI: { city: 'Ljubljana', latitude: 46.0569, longitude: 14.5058 },
  SE: { city: 'Stockholm', latitude: 59.3293, longitude: 18.0686 },
  CH: { city: 'Bern', latitude: 46.9481, longitude: 7.4474 },
  UK: { city: 'London', latitude: 51.5074, longitude: -0.1278 },
};

export async function getUserLocation(timeoutMillis = 500, shopCountry = 'FR'): Promise<LocationResponse> {
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const API_KEY = process.env.NEXT_PUBLIC_LOCATION_API_KEY!;
    const responsePromise = axios.get<LocationResponse>(
      `https://api.ipregistry.co/?key=${API_KEY}&fields=locations,location.longitude,location.latitude,location.country.code&hostname=true&pretty=true`,
    );

    const response = await withTimeout(responsePromise, timeoutMillis);

    if (response === 'timeout') {
      throw new Error('Location request timed out');
    }

    capturePosthogAnalytics('location_found', {
      location: `${response.data.location.latitude},${response.data.location.longitude}`,
    });

    if (response.data.location.latitude === 0 && response.data.location.longitude === 0) {
      throw new Oops('Invalid latitude & longitude given by ipregistry');
    }
    return response.data;
  } catch {
    // use shop country as fallback
    const countryCode = shopCountry?.toUpperCase() ?? 'FR';
    const coordinates = countriesCoordinates[countryCode]!;
    return {
      location: {
        country: { code: countryCode },
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      },
    };
  }
}

export const useUserLocationDetection = (timeoutMillis?: number) => {
  const { data: shop } = useShop();
  const { formMessage } = useFormMessageAtom();
  return useQuery({
    queryKey: [USER_LOCATION_DETECTION_QUERY_KEY],
    queryFn: () => getUserLocation(timeoutMillis, formMessage?.countryCode ?? shop?.country),
    enabled: Boolean(shop),
  });
};
