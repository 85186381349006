import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { secondsToHours } from 'date-fns';
import { useTranslation } from 'react-i18next';

import type { Cart } from '@getjust/gateway';

import { useCountryCodeAtom, useFormMessageAtom, useROFormMessageAtom } from '$hooks/state';
import { CART_QUERY_KEY } from '$src/constants';
import { useCustomRouter } from '$src/hooks/client';
import { useCartIdUpdater } from '$src/hooks/client/useCartIdUpdater';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { useCountryCodeContextFromLocalStorage } from '$src/hooks/state/useCountryCodeContextFromLocalStorage';
import { gateway } from '$src/http';
import { PrepareCartInput } from '$src/pages/api/[shopId]/prepare-cart';
import { _postMessage } from '$src/utils';

export const MUTATION_PREPARE_CART_KEY = 'MUTATION/PREPARE_CART';

export const usePrepareCart = () => {
  const { initCorrections } = useCorrectionsAtom();
  const { formMessage } = useFormMessageAtom();
  const queryClient = useQueryClient();
  const message = useROFormMessageAtom();
  const { track } = useTrackAmplitude();
  const { country } = useCountryCodeAtom();
  const router = useCustomRouter();
  const countryCodeContext = useCountryCodeContextFromLocalStorage() ?? formMessage?.countryCode ?? country;
  const updateCartId = useCartIdUpdater();
  const toast = useToast();
  const { t } = useTranslation('common');

  const mutation = useMutation({
    mutationFn: (cartInput: PrepareCartInput) =>
      gateway.post<Cart>(`/api/${formMessage?.shopId}/prepare-cart`, {
        cart: cartInput.cart,
        countryCodeContext: countryCodeContext,
      }),
    mutationKey: [MUTATION_PREPARE_CART_KEY],
    onSuccess: ({ data }) => {
      updateCartId(data.id);
      if (data?.corrections?.length) initCorrections(data?.corrections);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
      /* we do it here, as global MutationCache onSuccess callback is executed before this one. So, when it's executed,
         cartId state atom is not already set */
      if (data?.shipping?.ready === false) {
        queryClient.invalidateQueries({ queryKey: [CART_QUERY_KEY, data.id] });
      }
      track('Checkout form loaded', {
        totalAmount: data.totalAmount,
        currency: data.currency,
        product: data.lineItems?.length,
        'Cut-off delta': secondsToHours(formMessage?.deliveryCutOffDelta ?? 0),
        'Delivery date estimate': formMessage?.deliveryDateEstimated,
        'Location country': formMessage?.deliveryCountryCode,
        'Source URL': formMessage?.origin,
        variantsIds: formMessage?.cart?.lineItems?.map((item) => item.variantId).join(', '),
      });
    },
    onError: (err, payload) => {
      let errorType = '';
      if (err instanceof AxiosError) {
        if (err?.response?.data?.reason === 'productUnavailable') {
          errorType = 'variant_out_of_sync';
          toast({
            description: t('errors.productUnavailable'),
            status: 'error',
            position: 'top',
            duration: 10000,
            isClosable: true,
          });
        }

        if (err?.response?.data?.reason === 'productNotFound') {
          errorType = 'variant_not_found';
          toast({
            description: t('errors.productUnavailable'),
            status: 'error',
            position: 'top',
            duration: 10000,
            isClosable: true,
          });
        }

        if (err?.response?.data?.error?.reason === 'priceMismatch') {         
          errorType = 'price_miss_match';
          toast({
            description: t('errors.commons.priceMismatch'),
            status: 'error',
            position: 'top',
            duration: 10000,
            isClosable: true,
          });

          if (err?.response?.data?.error?.url) {
            setTimeout(() => {
              if (message?.redirectMode && message.origin) {
                location.href = err?.response?.data?.error?.url;
              } else {
                _postMessage({
                  emitter: 'JUST_FORM',
                  action: 'NAVIGATE',
                  orderUrl: err?.response?.data?.error?.url,
                });
              }
            }, 5000);
          }
        }
      }
      
      if(message?.cart?.lineItems?.every(item => item.variantId === 'NaN')) {
        errorType = 'no_variant_selected';
        toast({
          description: t('errors.commons.missingVariant'),
          status: 'error',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
      }

      track('Checkout form load failed', {
        payload: JSON.stringify(payload),
        discount: payload?.cart?.discountCodes?.join(' '),
        variantsIds: payload?.cart?.lineItems?.map((item) => item.variantId).join(', '),
        'Source URL': formMessage?.origin,
        'error code': errorType
      });
    },
  });

  return mutation;
};
