import { useQuery } from '@tanstack/react-query';

import type { UpsellProduct } from '@getjust/gateway';

import { useCart, useShop } from '$hooks/queries';
import { gateway } from '$src/http';

export const UPSELL_QUERY_KEY = 'QUERY/UPSELL_QUERY_KEY';

export function useUpsellProducts(disable = false) {
  const { data: shop } = useShop();
  const { data: cart } = useCart();

  return useQuery({
    queryKey: [UPSELL_QUERY_KEY, cart?.id],
    enabled: Boolean(shop?.id && cart?.id && !disable),
    select: (response) => response.data,
    queryFn: async () => gateway.get<UpsellProduct[]>(`/api/${shop?.id}/${cart?.id}/upsell-products`),
  });
}
