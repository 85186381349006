import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useCartIdUpdater } from '$src/hooks/client/useCartIdUpdater';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useCart } from '$src/hooks/queries';
import { gateway } from '$src/http';
import { SetPickupPointInput } from '$src/pages/api/[shopId]/[cartId]/set-pickup-point';

export const SET_PICKUP_POINT_MUTATION_KEY = 'MUTATION/SET_PICKUP_POINT';

export function useSetPickUpPoint() {
  const queryClient = useQueryClient();
  const baseUrl = useBaseApiUrl();
  const updateCartId = useCartIdUpdater();
  const { data: cart } = useCart();
  const { track } = useTrackAmplitude();

  return useMutation({
    mutationFn: (pickupPoint: SetPickupPointInput) =>
      gateway.post<Cart>(`${baseUrl}/set-pickup-point`, pickupPoint),
    mutationKey: [SET_PICKUP_POINT_MUTATION_KEY, baseUrl],
    onMutate: async ({ pickupPoint, shippingMethodId }) => {
      track('Pickup point selected', { transporter: cart?.shipping?.selected?.[0]?.aggregatedTitle ?? '' });
      await queryClient.cancelQueries({ queryKey: [CART_QUERY_KEY] });
      const previousCart = queryClient.getQueryData<Cart>([CART_QUERY_KEY, cart?.id]);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, previousCart?.id], (oldCart): Cart | undefined => {
        if (!oldCart) return oldCart;
        const selected = oldCart?.shipping?.methods?.find((method) => method.id === shippingMethodId);
        if (!selected) return oldCart;
        return {
          ...oldCart,
          shipping: {
            ...oldCart.shipping,
            selected: [
              {
                ...selected,
                pickupPoint: {
                  id: pickupPoint.id,
                  address1: pickupPoint.address1,
                  city: pickupPoint.city,
                  name: pickupPoint.name,
                  type: pickupPoint.type,
                  zip: pickupPoint.zip,
                },
              },
            ],
          },
        };
      });

      return {
        previousCart,
      };
    },
    onError: (_error, _shippinMethod, context) =>
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, context?.previousCart?.id], context?.previousCart),
    onSuccess: ({ data }) => {
      updateCartId(data.id);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
    },
  });
}
