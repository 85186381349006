import { useAtom } from 'jotai';

import { CartIdAtom, ZCartId, cartIdAtom } from '$src/business/atoms/cart';

export function useCartIdAtom() {
  const [cartId, setCartId] = useAtom(cartIdAtom);

  const onCartIdChange = (nextValue?: CartIdAtom) => {
    if (!nextValue) {
      setCartId(undefined);
      return;
    }

    const parse = ZCartId.safeParse(nextValue);
    if (parse.success) {
      setCartId(parse.data);
    } else {
      console.error('Error while parsing cart change', {
        error: parse.error,
        hook: useCartIdAtom.name,
      });
    }
  };

  return {
    cartId,
    onCartIdChange,
  };
}
