import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { CorrectionAtom, correctionsAtom } from '$business/atoms/corrections';

export function useCorrectionsAtom(): {
  corrections: CorrectionAtom[] | null;
  clearCorrections: () => void;
  initCorrections: (correctionsPayload: CorrectionAtom[]) => void;
} {
  const [corrections, setCorrections] = useAtom(correctionsAtom);

  const clearCorrections = useCallback(() => {
    setCorrections(null);
  }, [setCorrections]);

  const initCorrections = useCallback(
    (correctionsPayload: CorrectionAtom[]) => {
      setCorrections(correctionsPayload);
    },
    [setCorrections],
  );

  return { corrections, initCorrections, clearCorrections };
}
