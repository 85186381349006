import { useAtom } from 'jotai';
import { useCallback } from 'react';

import {
  AmplitudeEventAtom,
  AmplitudeEventCountsAtom,
  AmplitudeEventQueueAtom,
  ZAmplitudeEventAtom,
  amplitudeEventCountsAtom,
  amplitudeEventQueueAtom,
} from '$src/business/atoms/amplitude-event-queue';

export function useAmplitudeEventQueueAtom() {
  const [eventQueue, setEventQueue] = useAtom<AmplitudeEventQueueAtom>(amplitudeEventQueueAtom);
  const [eventCounts, setEventCounts] = useAtom<AmplitudeEventCountsAtom>(amplitudeEventCountsAtom);

  const addEventToQueue = useCallback(
    (nextValue?: AmplitudeEventAtom) => {
      if (!nextValue) {
        return;
      }

      const parse = ZAmplitudeEventAtom.safeParse(nextValue);
      if (parse.success) {
        const event = parse.data;

        const eventIdentifier = `${event.name}-${JSON.stringify(event.props)}`;
        const isEventAlreadyQueued = eventQueue.some(
          (queuedEvent) =>
            queuedEvent.name === event.name &&
            JSON.stringify(queuedEvent.props) === JSON.stringify(event.props),
        );
        setEventCounts((prevCounts) => {
          const currentCount = prevCounts[eventIdentifier] || 0;
          if (currentCount > 2) {
            return prevCounts;
          }
          const updatedCounts = { ...prevCounts, [eventIdentifier]: currentCount + 1 };
          if (currentCount <= 2 && !isEventAlreadyQueued) {
            setEventQueue((prevQueue) => [...prevQueue, event]);
          }
          return updatedCounts;
        });
      } else {
        console.error('Error while parsing event queue change', {
          error: parse.error,
          hook: useAmplitudeEventQueueAtom.name,
        });
      }
    },
    [eventQueue, setEventQueue, setEventCounts],
  );

  const clearEventQueue = useCallback(() => setEventQueue([]), [setEventQueue]);

  return { eventQueue, addEventToQueue, clearEventQueue };
}
