import { useRouter } from 'next/router';
import { useMemo } from 'react';

import type { NextRouter } from 'next/router';
import type { UrlObject } from 'url';

type Url = string | UrlObject;

interface IntarnalUseRouterReturnType extends Omit<NextRouter, 'push'> {
  push: (
    path: string,
    as?: Url,
    options?: { shallow?: boolean; locale?: string | false },
  ) => Promise<boolean>;
}

export const useCustomRouter = (): IntarnalUseRouterReturnType => {
  const router = useRouter();
  return useMemo(
    () => ({
      ...router,
      // push: (path: Url, as?: Url) => router.replace(path, as, { shallow: true, locale: message?.language }),
      push: (path: string, as?: Url, options: { shallow?: boolean; locale?: string | false } = {}) => {
        let searchParams = '';
        let index = 0;
        for (const key in router.query) {
          if (key !== 'slug') {
            searchParams += index ? '&' : '?';
            searchParams += `${key}=${router.query[key]}`;
            index++;
          }
        }
        const splitPath = path.split('?');
        return router.replace(`${splitPath[0]}${searchParams}`, as, options);
      },
    }),
    [router],
  );
};
